import { combineReducers } from 'redux'
import steps from './steps/reducer'
import userLocation from './userLocation/reducer'
import planSubscriptions from './planSubscriptions/reducer'
import incentivePrograms from './incentivePrograms/slice'
import mapAnalytics from './mapAnalytics'

export default combineReducers({
  steps,
  userLocation,
  planSubscriptions,
  incentivePrograms,
  mapAnalytics
})

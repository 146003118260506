import faker from 'faker/locale/en'
import { times } from 'lodash'
import { fakeAccountVehicle } from './fakers'

const mostRecentVehicleYear = 2021
export const fakeVehicleYears = times(31, (nb) => mostRecentVehicleYear - nb)
export const fakeVehicleMakes = ['Acura', 'Aston Martin', 'Honda'] as const
export type SupportedFakeMakesType = (typeof fakeVehicleMakes)[number]

export function isSupportedFakeMake(make: string): make is SupportedFakeMakesType {
  return fakeVehicleMakes.includes(make as SupportedFakeMakesType)
}

export const getFakeModels = (make?: SupportedFakeMakesType) => {
  switch (make) {
    case 'Acura':
      return [
        {
          name: 'ILX',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'MDX',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'NSX',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'RDX',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'RLX',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'TLX',
          uuid: faker.datatype.uuid()
        }
      ]
    case 'Aston Martin':
      return [
        {
          name: 'DB11',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'DBS Superleggera',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Rapide AMR',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Vantage',
          uuid: faker.datatype.uuid()
        }
      ]
    case 'Honda':
      return [
        {
          name: 'Accord',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Accord Hybrid',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Civic',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Clarity',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'CR-V',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'HR-V',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Insight',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Odyssey',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Passport',
          uuid: faker.datatype.uuid()
        },
        {
          name: 'Pilot',
          uuid: faker.datatype.uuid()
        }
      ]
    default:
      return []
  }
}

export const getFakeEngineTypes = (): { uuid: string; name: string }[] => [
  {
    name: 'Electric',
    uuid: faker.datatype.uuid()
  },
  {
    name: 'Gas',
    uuid: faker.datatype.uuid()
  },
  {
    name: 'Hybrid',
    uuid: faker.datatype.uuid()
  }
]

export const fakeAccountVehicles = times(3, (index) =>
  fakeAccountVehicle(index === 0 ? { color: undefined, licensePlate: undefined, licensePlateState: undefined } : {})
)

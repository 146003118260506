export enum MeasureTypes {
  Length = "length",
  Mass = "mass",
  // we need both duration and time FOR NOW because of a naming inconsistency
  // between user/region/org metrics and route metrics
  Duration = "duration",
  Time = "time",
  Volume = "volume",
  Distance = "distance",
  // We added currency so we can treat the cost metric with
  // other metrics (carbon and time)
  Currency = "currency",
}
export type MeasureType =
  | MeasureTypes.Length
  | MeasureTypes.Mass
  | MeasureTypes.Duration
  | MeasureTypes.Time
  | MeasureTypes.Volume
  | MeasureTypes.Distance
  | MeasureTypes.Currency;

export const DEFAULT_UNITS_BY_MEASURE_TYPE: Record<
  MeasureType,
  convert.Unit | undefined
> = {
  [MeasureTypes.Length]: "m",
  [MeasureTypes.Mass]: "kg",
  // we need both duration and time FOR NOW because of a naming inconsistency
  // between user/region/org metrics and route metrics
  [MeasureTypes.Duration]: "s",
  [MeasureTypes.Time]: "s",
  [MeasureTypes.Volume]: "l",
  [MeasureTypes.Distance]: "m",
  [MeasureTypes.Currency]: undefined,
};

export const IMPERIAL_UNITS_BY_MEASURE_TYPE: Record<
  MeasureType,
  convert.Unit | undefined
> = {
  [MeasureTypes.Length]: "ft",
  [MeasureTypes.Mass]: "lb",
  // we need both duration and time FOR NOW because of a naming inconsistency
  // between user/region/org metrics and route metrics
  [MeasureTypes.Duration]: "s",
  [MeasureTypes.Time]: "s",
  [MeasureTypes.Volume]: "gal",
  [MeasureTypes.Distance]: "mi",
  [MeasureTypes.Currency]: undefined,
};

export const METRIC_UNITS: Record<MeasureType, convert.Unit | undefined> = {
  ...DEFAULT_UNITS_BY_MEASURE_TYPE,
  [MeasureTypes.Distance]: "km",
};

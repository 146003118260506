import { createContext, useContext } from 'react'
import { ThemeProviderProps } from '@nivo/core'
import { ModeCategory } from '@commutifi/modes'
import { ChartsTokens, dynamicColors } from '../theme/index'

export interface ChartsThemeContextValue {
  customPalettes?: {
    blue: string[]
    blueDescending: string[]
    perModeCategory: Record<ModeCategory, string[]>
  }
  colorScheme?: string
  theme?: ThemeProviderProps['theme']
  tokens: ChartsTokens
}

export const neutralBlueColorsOrdered = [
  dynamicColors.chartNeutralBlue600,
  dynamicColors.chartNeutralBlue500,
  dynamicColors.chartNeutralBlue400,
  dynamicColors.chartNeutralBlue300,
  dynamicColors.chartNeutralBlue200,
  dynamicColors.chartNeutralBlue100
]

export const neutralBlueColors = [
  dynamicColors.chartNeutralBlue500,
  dynamicColors.chartNeutralBlue200,
  dynamicColors.chartNeutralBlue600,
  dynamicColors.chartNeutralBlue100,
  dynamicColors.chartNeutralBlue400,
  dynamicColors.chartNeutralBlue300
]

export const carpoolColors = [
  dynamicColors.chartAccentYellow200,
  dynamicColors.chartAccentYellow600,
  dynamicColors.chartAccentYellow500,
  dynamicColors.chartAccentYellow400,
  dynamicColors.chartAccentYellow300,
  dynamicColors.chartAccentYellow100
]

export const sharedRideColors = [
  dynamicColors.chartAccentBeige400,
  dynamicColors.chartAccentBeige600,
  dynamicColors.chartAccentBeige500,
  dynamicColors.chartAccentBeige300,
  dynamicColors.chartAccentBeige200,
  dynamicColors.chartAccentBeige100
]

export const bikeColors = [
  dynamicColors.chartAccentMint500,
  dynamicColors.chartAccentMint600,
  dynamicColors.chartAccentMint400,
  dynamicColors.chartAccentMint300,
  dynamicColors.chartAccentMint200,
  dynamicColors.chartAccentMint100
]

export const publicTransitColors = [
  dynamicColors.chartAccentGreen400,
  dynamicColors.chartAccentGreen600,
  dynamicColors.chartAccentGreen500,
  dynamicColors.chartAccentGreen300,
  dynamicColors.chartAccentGreen200,
  dynamicColors.chartAccentGreen100
]

export const walkColors = [
  dynamicColors.chartAccentPistachio300,
  dynamicColors.chartAccentPistachio600,
  dynamicColors.chartAccentPistachio500,
  dynamicColors.chartAccentPistachio400,
  dynamicColors.chartAccentPistachio200,
  dynamicColors.chartAccentPistachio100
]

export const driveColors = [
  dynamicColors.chartAccentPurple500,
  dynamicColors.chartAccentPurple600,
  dynamicColors.chartAccentPurple400,
  dynamicColors.chartAccentPurple300,
  dynamicColors.chartAccentPurple200,
  dynamicColors.chartAccentPurple100
]

export const remoteColors = [
  dynamicColors.chartAccentPink400,
  dynamicColors.chartAccentPink600,
  dynamicColors.chartAccentPink500,
  dynamicColors.chartAccentPink300,
  dynamicColors.chartAccentPink200,
  dynamicColors.chartAccentPink100
]

export const defaultConfig: ChartsThemeContextValue = {
  customPalettes: {
    blue: neutralBlueColors,
    blueDescending: neutralBlueColorsOrdered,
    perModeCategory: {
      [ModeCategory.Bike]: bikeColors,
      [ModeCategory.Carpool]: carpoolColors,
      [ModeCategory.Drive]: driveColors,
      [ModeCategory.PublicTransit]: publicTransitColors,
      [ModeCategory.Remote]: remoteColors,
      [ModeCategory.SharedRide]: sharedRideColors,
      [ModeCategory.Walk]: walkColors,
      [ModeCategory.None]: neutralBlueColors
    }
  },
  tokens: dynamicColors
}

export const ChartsThemeContext = createContext<Omit<ChartsThemeContextValue, 'children'>>(defaultConfig)
export const useChartsTheme = () => {
  const context = useContext(ChartsThemeContext)
  return context
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MapType } from 'views/SignedIn/BusinessDashboard/views/Analytics/views/CommuteAnalytics/views/Map/types'

type MapAnalyticsState = {
  mapType: MapType
  selectedMetric: string | null
}

const initialState: MapAnalyticsState = {
  mapType: MapType.Density,
  selectedMetric: null
}

const mapAnalyticsSlice = createSlice({
  name: 'mapAnalytics',
  initialState,
  reducers: {
    setMapType(state, action: PayloadAction<MapType>) {
      state.mapType = action.payload
    },
    setSelectedMetric(state, action: PayloadAction<string | null>) {
      state.selectedMetric = action.payload
    }
  }
})

export const { setMapType, setSelectedMetric } = mapAnalyticsSlice.actions

export default mapAnalyticsSlice.reducer

// import the library
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa
} from '@fortawesome/free-brands-svg-icons'
import {
  faBell,
  faBellSlash,
  faCalendar,
  faCheckCircle,
  faCircle as faCircleRegular,
  faClock as faClockRegular,
  faClone,
  faCreditCard as faCreditCardRegular,
  faEnvelope,
  faEye,
  faEyeSlash,
  faUser as faUserRegular
} from '@fortawesome/free-regular-svg-icons'
// import your icons
import {
  faArrowTrendDown,
  faArrowTrendUp,
  faBars,
  faBold,
  faBookOpen,
  faBuilding,
  faBuildingColumns,
  faCalendar as faCalendarSolid,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartPie,
  faCheckCircle as faCheckCircleSolid,
  faChevronRight as faChevronRightSolid,
  faCircle,
  faClipboardList,
  faClock,
  faCloud,
  faCompass,
  faCreditCard as faCreditCardSolid,
  faDollarSign,
  faDumbbell,
  faEnvelope as faEnvelopeSolid,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faEye as faEyeSolid,
  faFileInvoiceDollar,
  faFileZipper,
  faFilter,
  faGlobeAmericas,
  faHandshake,
  faHome,
  faIdBadge,
  faIdCard,
  faInfoCircle,
  faLock,
  faMapMarkerAlt,
  faMoneyBillWave,
  faMoon,
  faPaperPlane,
  faParking as faParkingSolid,
  faPen,
  faPencilAlt,
  faPersonMilitaryPointing,
  faPhone,
  faPlug,
  faPlus as faPlusSolid,
  faQuestionCircle,
  faRedo,
  faRoute,
  faSchool,
  faSearch,
  faShoppingCart,
  faShower,
  faSignOutAlt,
  faStar,
  faStore,
  faSuitcase,
  faTable,
  faTachometerAlt,
  faTag as faTagSolid,
  faTags,
  faThumbTack,
  faTicketAlt,
  faTimesCircle,
  faTrash,
  faTrashAlt as faTrashAltSolid,
  faTree,
  faTrophy,
  faUndo,
  faUniversity,
  faUpload as faUploadSolid,
  faUser,
  faUserFriends,
  faUsers,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { faCircle as faCircleDuotone } from '@fortawesome/pro-duotone-svg-icons'
import {
  faBallotCheck,
  faCreditCard,
  faDownload,
  faEdit,
  faTimes,
  faTrees,
  faUpload
} from '@fortawesome/pro-light-svg-icons'
import {
  faAnalytics,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBriefcase,
  faBriefcaseMedical,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleDollar,
  faCircleExclamation,
  faCloud as faCloudRegular,
  faCoffeeTogo,
  faCog,
  faEmptySet,
  faFileInvoice,
  faFileInvoiceDollar as faFileInvoiceDollarPro,
  faFont,
  faFrown,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faIdCardAlt,
  faInfoCircle as faInfoCircleRegular,
  faItalic,
  faLink,
  faList,
  faListOl,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faQuestionCircle as faQuestionCircleRegular,
  faRedoAlt,
  faRoute as faRouteRegular,
  faSearch as faSearchRegular,
  faSmile,
  faSpinnerThird,
  faStrikethrough,
  faTag,
  faTrashAlt,
  faTurnDownLeft,
  faUnderline,
  faUsers as faUsersPro
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBookBookmark,
  faBookUser,
  faCircleSmall,
  faCircleXmark,
  faClipboardCheck,
  faFlag,
  faFlaskRoundPotion,
  faGear,
  faGiftCard,
  faLocationCircle,
  faMug,
  faPeopleSimple,
  faRotate,
  faRotateLeft,
  faRotateRight,
  faSquarePollVertical,
  faSunBright
} from '@fortawesome/pro-solid-svg-icons'
import * as customIcons from '@commutifi-fe/custom-icons/fontawesome'

library.add(
  faMoon,
  faSunBright,
  faGear,
  faShower,
  faGiftCard,
  faTagSolid,
  faMoneyBillWave,
  faFileZipper,
  faCalendarSolid,
  faThumbTack,
  faStore,
  faPersonMilitaryPointing,
  faClipboardCheck,
  faCircleSmall,
  faCircleXmark,
  faCircleExclamation,
  faFileInvoiceDollarPro,
  faTurnDownLeft,
  faFont,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faChevronDown,
  faLink,
  faItalic,
  faBold,
  faUnderline,
  faList,
  faListOl,
  faStrikethrough,
  faRotate,
  faRotateLeft,
  faRotateRight,
  faInfoCircleRegular,
  faChevronRightSolid,
  faBuildingColumns,
  faBriefcaseMedical,
  faPeopleSimple,
  faSquarePollVertical,
  faFlaskRoundPotion,
  faFlag,
  faBookBookmark,
  faCompass,
  faTrees,
  faCloud,
  faCloudRegular,
  faIdBadge,
  faTicketAlt,
  faMug,
  faDollarSign,
  faMapMarkerAlt,
  faGlobeAmericas,
  faTachometerAlt,
  faRoute,
  faRouteRegular,
  faTable,
  faSignOutAlt,
  faEnvelope,
  faEnvelopeSolid,
  faClipboardList,
  faExchangeAlt,
  faEdit,
  faCircle,
  faCircleRegular,
  faCircleDuotone,
  faXmark,
  faBallotCheck,
  faEye,
  faEyeSolid,
  faEyeSlash,
  faPhone,
  faUser,
  faUserRegular,
  faUsers,
  faIdCard,
  faPencilAlt,
  faBuilding,
  faDownload,
  faUpload,
  faUploadSolid,
  faFilter,
  faBell,
  faBellSlash,
  faRedoAlt,
  faArrowLeft,
  faArrowRight,
  faEmptySet,
  faPaperPlane,
  faSuitcase,
  faPlus,
  faPlusSolid,
  faPlusCircle,
  faMinusCircle,
  faPen,
  faTimes,
  faTimesCircle,
  faClock,
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faCaretUp,
  faUndo,
  faRedo,
  faTag,
  faLock,
  faTrophy,
  faCheck,
  faExclamationCircle,
  faBan,
  faTrashAlt,
  faBars,
  faExclamationTriangle,
  faAngleLeft,
  faAngleRight,
  faParkingSolid,
  faStar,
  faHome,
  faCheckCircleSolid,
  faCheckCircle,
  faSmile,
  faFrown,
  faClockRegular,
  faBriefcaseMedical,
  faTrash,
  faUsersPro,
  faAnalytics,
  faFileInvoice,
  faBriefcase,
  faCog,
  faCreditCard,
  faCreditCardRegular,
  faCreditCardSolid,
  faUniversity,
  faCcVisa,
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcJcb,
  faCcDinersClub,
  faDumbbell,
  faHandshake,
  faShoppingCart,
  faCoffeeTogo,
  faTrashAltSolid,
  faBookOpen,
  faInfoCircle,
  faSchool,
  faQuestionCircle,
  faQuestionCircleRegular,
  faSchool,
  faFileInvoiceDollar,
  faClone,
  faChartPie,
  faUserFriends,
  faTree,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faSearchRegular,
  faPlug,
  faLocationCircle,
  faTags,
  faBookUser,
  faSpinnerThird,
  faCalendar,
  faIdCardAlt,
  faArrowTrendUp,
  faArrowTrendDown,
  faCircleDollar,
  ...Object.values(customIcons)
)

import { uuidPattern } from 'utils/helpers'

/*
 * Available pages enums
 */
export enum PublicPages {
  Auth = 'auth',
  ResetPassword = 'reset-password',
  CreateAccount = 'create'
}

export enum EnterpriseSurveyPages {
  Auth = 'auth',
  PublicWelcome = 'welcome',
  Onboarding = 'onboarding',
  Register = 'register',
  Success = 'success'
}

export enum EnterpriseRegistrationPages {
  Success = 'success'
}

export enum AdminDashboardPages {
  Compliance = 'compliance',
  Analytics = 'analytics',
  Subscriptions = 'subscriptions',
  Plans = 'plans',
  Billing = 'billing',
  Employees = 'employees',
  Groups = 'groups',
  Offices = 'offices',
  Enterprises = 'children',
  Wallets = 'wallets',
  Announcements = 'announcements',
  Enrollments = 'enrollments',
  Submissions = 'submissions',
  Settings = 'settings',
  Integrations = 'integrations',
  Managers = 'managers'
}

enum AnalyticsSubPages {
  Commute = 'commute',
  Surveys = 'surveys',
  Report = 'report'
}

export const AdminDashboardSubPages = AnalyticsSubPages
export const AdminSubPagesByPage = {
  [AdminDashboardPages.Analytics]: AnalyticsSubPages
}

export enum CommuteAnalyticsTabViews {
  Map = 'map',
  SummaryAndCommuting = 'summary',
  Schedule = 'schedule',
  Sustainability = 'sustainability',
  Vehicle = 'vehicle'
}

export enum ActionPages {
  Create = 'create'
}

export enum AdminBillingPages {
  AddPayment = 'add-payment-method',
  Balance = 'balance',
  Payment = 'payment',
  Invoices = 'invoices'
}

export enum AdminEmployeePages {
  Groups = 'groups',
  Employees = 'employees'
}

export enum AdminGroupPages {
  Members = 'members'
}

export enum AdminPlansPages {
  Plans = 'plans',
  Subscriptions = 'subscriptions'
}

export enum AdminSettingsPages {
  Integrations = 'integrations',
  Managers = 'managers'
}

export enum AdminCompliancePages {
  DDOT = 'ddot'
}

export enum AdminWalletPages {
  Enrollments = 'enrollments',
  Wallets = 'wallets'
}

export enum AdminAnnouncementPages {
  Announcements = 'announcements'
}

export enum AdminWalletCreationPages {
  Plans = 'plans',
  Card = 'card',
  SpendingCategories = 'categories',
  Allocation = 'allocation',
  Groups = 'groups',
  Details = 'details',
  Terms = 'terms'
}

export enum AdminComplianceCreationPages {
  Profile = 'profile',
  Options = 'options',
  Answers = 'answers'
}

export enum AdminEnterpriseCreationPages {
  Profile = 'profile',
  Plans = 'plans',
  Invitation = 'invitation'
}

export enum CommuterDashboardPages {
  Dashboard = 'dashboard',
  CommuterGuide = 'guide',
  Routes = 'routes',
  Catalog = 'catalog',
  Bookings = 'bookings',
  Trips = 'trips',
  Settings = 'settings',
  Card = 'card',
  Wallets = 'wallets',
  Incentive = 'incentive',
  AiSearch = 'search',
  // !Available only in the header menu (or mobile menu)
  Connect = 'connect'
}

export enum CheckoutPages {
  Checkout = 'checkout',
  Success = 'success',
  Payment = 'payment'
}

export enum CommuterDashboardPathsParams {
  SettingsPage = 'settingsPage',
  CommuteProfileId = 'commuteProfileId',
  IncentiveProgramId = 'incentiveProgramId',
  BookingPage = 'bookingPage',
  BookingId = 'bookingId',
  PlanId = 'planId',
  WalletId = 'walletId',
  TripPage = 'tripsPage',
  GuideId = 'sectionId',
  RouteId = 'routeId'
}

export enum SettingsPages {
  Account = 'account',
  Commute = 'commute',
  CreateCommute = 'create'
}

export enum BookingDetailsPages {
  Overview = 'overview',
  Billing = 'billing',
  Registration = 'registration',
  Cancel = 'cancel'
}

/*
 * React-router paths parameter enums
 */
export enum PublicPathsParams {
  AccountId = 'accountId',
  AdminId = 'adminId',
  Shortlink = 'shortlink',
  RegistrationId = 'enterpriseRegistrationId'
}

export enum PrivatePathsParams {
  InvoiceId = 'invoiceId'
}

export enum AdminPathsParams {
  MenuPage = 'page',
  SubMenuPage = 'subPage',
  EmployeeOrGroupId = 'employeeOrGroupId',
  EnterpriseId = 'enterpriseId',
  GroupId = 'groupId',
  SubscriptionOrPlanId = 'subscriptionOrPlanId',
  EnrolmentOrWalletId = 'enrolmentOrWalletId',
  AnnouncementId = 'announcementId',
  SubmissionId = 'submissionId',
  CreationStep = 'step'
}

export enum AdminDashboardParams {
  AnalyticReportId = 'analyticReportId'
}

export enum AdminBillingPathsParams {
  Page = 'billingPage'
}

export enum AdminSubPagePathsParams {
  EmployeePage = 'employeesPage',
  GroupPage = 'groupsPage',
  EnterprisePage = 'enterprisePage',
  WalletPage = 'walletPage',
  AnnouncementPage = 'announcementPage',
  CompliancePage = 'compliancePage',
  PlanPage = 'planPage',
  CommuteAnalyticsPage = 'commuteAnalyticsPage',
  SurveyAnalyticsPage = 'surveyAnalyticsPage',
  SettingsPage = 'settingsPage'
}

export enum CommuterPathsParams {
  Page = 'page',
  SuperAdminId = 'superAdminId'
}

export enum EnterpriseSurveyPathsParams {
  Step = 'step'
}

/**
 * Application Nested rooting objects:
 * PublicRouting, CommonRouting, CommuterRouting, AdminRouting, etc.
 *
 * Two key concepts to understand here:
 *  1. Path: A representation of multiple routes a user can go
 *     through URLs. Usually contains parameters like /plans/:planId
 *  2. Route: Simply a path with all parameters filled (/plans/1)
 *
 * So path is like a template of what the url looks like
 * and then the route is what the url actually looks like
 *
 * In other words, a path is simply a pattern potentially matching multiple routes.
 * @example Take this path: `plans/:planId`. It has an infinity of routes it matches:
 *          `plans/1`, `plans/`2, `plans/3`, ...
 */
export const PublicRouting = {
  root: {
    path: '/',
    getRoute(): string {
      return this.path
    }
  },
  auth: {
    path: '/auth',
    getRoute(): string {
      return this.path
    }
  },
  oAuth: {
    path: '',
    getRoute(): string {
      return this.path
    },
    sso: {
      path: '/sso/callback',
      getRoute(): string {
        return this.path
      }
    },
    mobilityService: {
      path: '/mobility-services/callback',
      getRoute(): string {
        return this.path
      }
    },
    arc: {
      path: '/arc',
      getRoute(): string {
        return this.path
      }
    },
    stripe: {
      path: '/stripe',
      getRoute(): string {
        return this.path
      },
      customConnect: {
        path: '/stripe/custom',
        getRoute(): string {
          return this.path
        }
      }
    }
  },
  emailVerification: {
    path: '/email-verification',
    getRoute(): string {
      return this.path
    }
  },
  passwordCreation: {
    path: `/accounts/:${PublicPathsParams.AccountId}(${uuidPattern})`,
    getRoute(id: string): string {
      return this.path.replace(`:${PublicPathsParams.AccountId}`, id)
    }
  },
  enterpriseSurvey: {
    path: `/to/:${PublicPathsParams.Shortlink}`,
    get optional() {
      return { path: `${this.path}?` }
    },
    getRoute(id: string): string {
      return this.path.replace(`:${PublicPathsParams.Shortlink}`, id)
    },
    get auth() {
      return {
        path: `${this.path}/${EnterpriseSurveyPages.Auth}`,
        getRoute: this.getRoute
      }
    },
    get publicWelcome() {
      return {
        path: `${this.path}/${EnterpriseSurveyPages.PublicWelcome}`,
        getRoute: this.getRoute
      }
    },

    get onboarding() {
      return {
        path: `${this.path}/onboarding`,
        getRoute: this.getRoute,
        get step() {
          return {
            path: `${this.path}/:${EnterpriseSurveyPathsParams.Step}(step[1-9][0-9]{0,1})`,
            getRoute(shortlink: string, stepNb: string) {
              return this.path
                .replace(`:${PublicPathsParams.Shortlink}`, shortlink)
                .replace(`:${EnterpriseSurveyPathsParams.Step}`, stepNb)
            }
          }
        },

        get register() {
          return {
            path: `${this.path}/${EnterpriseSurveyPages.Register}`,
            getRoute(): string {
              return this.path
            }
          }
        },
        get success() {
          return {
            path: `${this.path}/${EnterpriseSurveyPages.Success}`,
            getRoute(): string {
              return this.path
            }
          }
        }
      }
    }
  },
  enterpriseRegistration: {
    path: `/r/:${PublicPathsParams.RegistrationId}`,
    getRoute(id: string): string {
      return this.path.replace(`:${PublicPathsParams.RegistrationId}`, id)
    }
  },
  complianceCalculator: {
    path: '/compliance/calculator',
    getRoute(): string {
      return this.path
    }
  }
}

export const CommonRouting = {
  select: {
    path: '/select',
    getRoute(): string {
      return this.path
    }
  },
  invoice: {
    path: '/invoices',
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:${PrivatePathsParams.InvoiceId}`,
        getRoute(id: string): string {
          return this.path.replace(`:${PrivatePathsParams.InvoiceId}`, id)
        }
      }
    }
  }
}

const getCheckoutSubset = <T extends { path: string; getRoute: (...args: string[]) => string }>(thisObj: T) => ({
  path: `${thisObj.path}/${CheckoutPages.Checkout}`,
  getRoute: thisObj.getRoute,
  get payment() {
    return {
      path: `${this.path}/${CheckoutPages.Payment}`,
      getRoute: this.getRoute
    }
  },
  get success() {
    return {
      path: `${this.path}/${CheckoutPages.Success}`,
      getRoute: this.getRoute
    }
  }
})

export const CommuterRouting = {
  basePage: {
    path: `/:${CommuterPathsParams.Page}`,
    getRoute(page: `${CommuterDashboardPages}`): string {
      return this.path.replace(`:${CommuterPathsParams.Page}`, page)
    },
    superAdmin: {
      path: `/accounts/:${CommuterPathsParams.SuperAdminId}(${uuidPattern})/:${CommuterPathsParams.Page}`,
      getRoute(accountId: string, page: `${CommuterDashboardPages}`): string {
        return this.path
          .replace(CommuterPathsParams.SuperAdminId, accountId)
          .replace(`:${CommuterPathsParams.Page}`, page)
      }
    }
  },
  dashboard: {
    path: `/${CommuterDashboardPages.Dashboard}`,
    getRoute(): string {
      return this.path
    }
  },
  commuterGuide: {
    path: `/${CommuterDashboardPages.CommuterGuide}`,
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.GuideId}`,
        getRoute(id: string): string {
          return this.path.replace(`:${CommuterDashboardPathsParams.GuideId}`, id)
        },
        get terms() {
          return {
            path: `${this.path}/terms`,
            getRoute: this.getRoute
          }
        }
      }
    }
  },
  routes: {
    path: `/${CommuterDashboardPages.Routes}`,
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.RouteId}`,
        getRoute(id: string): string {
          return this.path.replace(`:${CommuterDashboardPathsParams.RouteId}`, id)
        }
      }
    }
  },
  catalog: {
    path: `/${CommuterDashboardPages.Catalog}`,
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:type?(p|w)`,
        getRoute(): string {
          return this.path.replace('/:type?(p|w)', '')
        },
        get plans() {
          return {
            path: `/${CommuterDashboardPages.Catalog}/p/:${CommuterDashboardPathsParams.PlanId}`,
            getRoute(id: string): string {
              return this.path.replace(`:${CommuterDashboardPathsParams.PlanId}`, id)
            },
            get checkout() {
              return getCheckoutSubset(this)
            },
            get terms() {
              return {
                path: `${this.path}/terms`,
                getRoute: this.getRoute
              }
            }
          }
        },
        get wallets() {
          return {
            path: `/${CommuterDashboardPages.Catalog}/w/:${CommuterDashboardPathsParams.WalletId}`,
            getRoute(id: string): string {
              return this.path.replace(`:${CommuterDashboardPathsParams.WalletId}`, id)
            },
            get checkout() {
              return getCheckoutSubset(this)
            }
          }
        }
      }
    }
  },
  bookings: {
    path: `/${CommuterDashboardPages.Bookings}`,
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.BookingId}`,
        getRoute(id: string): string {
          return this.path.replace(`:${CommuterDashboardPathsParams.BookingId}`, id)
        },
        get optional() {
          return {
            path: `${this.path}?`,
            getRoute(): string {
              return this.path
            }
          }
        },
        get cancel() {
          return {
            path: `${this.path}/${BookingDetailsPages.Cancel}`,
            getRoute: this.getRoute
          }
        },
        get overview() {
          return {
            path: `${this.path}/${BookingDetailsPages.Overview}`,
            getRoute: this.getRoute
          }
        },
        get billing() {
          return {
            path: `${this.path}/${BookingDetailsPages.Billing}`,
            getRoute: this.getRoute
          }
        },
        get registration() {
          return {
            path: `${this.path}/${BookingDetailsPages.Registration}`,
            getRoute: this.getRoute
          }
        }
      }
    },
    get pages() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.BookingPage}`,
        get optional() {
          return {
            path: `${this.path}?`,
            getRoute(): string {
              return this.path
            }
          }
        },
        getRoute(id: string, page: string): string {
          return this.path
            .replace(`:${CommuterDashboardPathsParams.BookingId}`, id)
            .replace(`:${CommuterDashboardPathsParams.BookingPage}`, page)
        }
      }
    }
  },
  trips: {
    path: `/${CommuterDashboardPages.Trips}`,
    getRoute(): string {
      return this.path
    },
    get pages() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.TripPage}`,
        get optional() {
          return {
            path: `${this.path}?`,
            getRoute(): string {
              return this.path
            }
          }
        },
        getRoute(page: string): string {
          return this.path.replace(`:${CommuterDashboardPathsParams.TripPage}`, page)
        }
      }
    }
  },
  wallets: {
    path: `/${CommuterDashboardPages.Wallets}`,
    getRoute(): string {
      return this.path
    }
  },
  search: {
    path: `/${CommuterDashboardPages.AiSearch}`,
    getRoute(): string {
      return this.path
    }
  },
  connect: {
    path: `/${CommuterDashboardPages.Connect}`,
    getRoute(): string {
      return this.path
    }
  },
  card: {
    path: `/${CommuterDashboardPages.Card}`,
    getRoute(): string {
      return this.path
    }
  },
  settings: {
    path: `/${CommuterDashboardPages.Settings}`,
    getRoute(): string {
      return this.path
    },
    get pages() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.SettingsPage}`,
        get optional() {
          return {
            path: `${this.path}?`,
            getRoute(): string {
              return this.path
            }
          }
        },
        getRoute(page: string): string {
          return this.path.replace(`:${CommuterDashboardPathsParams.SettingsPage}`, page)
        }
      }
    },
    get account() {
      return {
        path: `${this.path}/${SettingsPages.Account}`,
        getRoute(): string {
          return this.path
        }
      }
    },
    get commute() {
      return {
        path: `${this.path}/${SettingsPages.Commute}`,
        getRoute(): string {
          return this.path
        },
        get create() {
          return {
            path: `${this.path}/${SettingsPages.CreateCommute}`,
            getRoute(): string {
              return this.path
            }
          }
        },
        get detail() {
          return {
            path: `${this.path}/:${CommuterDashboardPathsParams.CommuteProfileId}`,
            get optional() {
              return {
                path: `${this.path}?`,
                getRoute(): string {
                  return this.path
                }
              }
            },
            getRoute(id: string): string {
              return this.path.replace(`:${CommuterDashboardPathsParams.CommuteProfileId}`, id)
            }
          }
        }
      }
    }
  },
  incentive: {
    path: `/${CommuterDashboardPages.Incentive}/:${CommuterDashboardPathsParams.IncentiveProgramId}`,
    getRoute(id: string): string {
      return this.path.replace(`:${CommuterDashboardPathsParams.IncentiveProgramId}`, id)
    }
  }
}

export const AdminRouting = {
  root: {
    path: `/admins/:${PublicPathsParams.AdminId}`,
    getRoute(id: string): string {
      return this.path.replace(`:${PublicPathsParams.AdminId}`, id)
    },

    get compliance() {
      return {
        path: `${this.path}/${AdminDashboardPages.Compliance}/:${AdminSubPagePathsParams.CompliancePage}(create)`,
        getRoute(adminId: string) {
          return this.path
            .replace(`:${PublicPathsParams.AdminId}`, adminId)
            .replace(`/:${AdminSubPagePathsParams.CompliancePage}(create)`, '')
        },
        get creation() {
          return {
            path: `${this.path.replace(
              `:${AdminSubPagePathsParams.CompliancePage}(create)`,
              `create/:${AdminPathsParams.CreationStep}(${Object.values(AdminComplianceCreationPages).join('|')})?`
            )}`,
            getRoute(adminId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(
                  `/:${AdminPathsParams.CreationStep}(${Object.values(AdminComplianceCreationPages).join('|')})?`,
                  ''
                )
            }
          }
        }
      }
    },

    get analytics() {
      return {
        path: `${this.path}/${AdminDashboardPages.Analytics}`,
        getRoute: this.getRoute,

        get commute() {
          const baseCommutePath = `${this.path}/${AnalyticsSubPages.Commute}`
          const availablePages = Object.values(CommuteAnalyticsTabViews).join('|')

          return {
            path: `${baseCommutePath}/:${AdminSubPagePathsParams.CommuteAnalyticsPage}(${availablePages})?`,
            getRoute(adminId: string, commuteAnalyticsTab?: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(
                  `${!commuteAnalyticsTab ? '/' : ''}:${AdminSubPagePathsParams.CommuteAnalyticsPage}(${availablePages})?`,
                  commuteAnalyticsTab || ''
                )
            }
          }
        },
        get report() {
          return {
            path: `${this.path}/${AnalyticsSubPages.Report}`,
            getRoute: this.getRoute,
            get details() {
              return {
                path: `${this.path}/:${AdminDashboardParams.AnalyticReportId}`,
                getRoute(adminId: string, id: string): string {
                  return this.path
                    .replace(`:${PublicPathsParams.AdminId}`, adminId)
                    .replace(`:${AdminDashboardParams.AnalyticReportId}`, id)
                }
              }
            }
          }
        },
        get surveys() {
          const baseCommutePath = `${this.path}/${AnalyticsSubPages.Surveys}`
          const availablePages = Object.values(CommuteAnalyticsTabViews).join('|')

          return {
            path: `${baseCommutePath}/:${AdminSubPagePathsParams.SurveyAnalyticsPage}(${availablePages})?`,
            getRoute(adminId: string, surveyAnalyticsPage: string = CommuteAnalyticsTabViews.SummaryAndCommuting) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminSubPagePathsParams.SurveyAnalyticsPage}(${availablePages})`, surveyAnalyticsPage)
            }
          }
        }
      }
    },

    get billing() {
      return {
        path: `${this.path}/${AdminDashboardPages.Billing}`,
        getRoute(id: string): string {
          return this.path.replace(`:${PublicPathsParams.AdminId}`, id)
        },
        get payment() {
          return {
            path: `${this.path}/${AdminBillingPages.Payment}`,
            getRoute(id: string): string {
              return this.path.replace(`:${PublicPathsParams.AdminId}`, id)
            },
            get add() {
              return {
                path: `${this.path}/${AdminBillingPages.AddPayment}`,
                getRoute(id: string): string {
                  return this.path.replace(`:${PublicPathsParams.AdminId}`, id)
                }
              }
            }
          }
        },

        get balance() {
          return {
            path: `${this.path}/${AdminBillingPages.Balance}`,
            getRoute(id: string): string {
              return this.path.replace(`:${PublicPathsParams.AdminId}`, id)
            }
          }
        },
        get invoices() {
          return {
            path: `${this.path}/${AdminBillingPages.Invoices}`,
            getRoute(id: string): string {
              return this.path.replace(`:${PublicPathsParams.AdminId}`, id)
            }
          }
        }
      }
    },

    get employees() {
      const availablePages = `${AdminDashboardPages.Employees}|${AdminDashboardPages.Groups}`
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.EmployeePage}(${availablePages})/:${AdminPathsParams.EmployeeOrGroupId}(${uuidPattern})?`,
        getRoute(enterpriseId: string, page: AdminEmployeePages) {
          return `${AdminRouting.root.getRoute(enterpriseId)}/${page}`
        },
        get detail() {
          return {
            path: this.path.replace('?', ''),
            getRoute(adminId: string, page: AdminEmployeePages, id: string) {
              return `${AdminRouting.root.employees.getRoute(adminId, page)}/${id}`
            }
          }
        },
        get groups() {
          return {
            path: `${AdminRouting.root.path}/${AdminEmployeePages.Groups}`,
            getRoute(adminId: string) {
              return `${AdminRouting.root.getRoute(adminId)}/${AdminEmployeePages.Groups}`
            },
            get detail() {
              return {
                path: `${this.path}/:${AdminPathsParams.GroupId}(${uuidPattern})/:${AdminSubPagePathsParams.GroupPage}(members)?`,
                getRoute(adminId: string, groupId: string) {
                  return this.path
                    .replace(`:${PublicPathsParams.AdminId}`, adminId)
                    .replace(`:${AdminPathsParams.GroupId}(${uuidPattern})`, groupId)
                    .replace(`/:${AdminSubPagePathsParams.GroupPage}(members)?`, '')
                }
              }
            },
            get members() {
              return {
                path: `${this.path}/:${AdminPathsParams.GroupId}/members`,
                getRoute(adminId: string, employeeId: string) {
                  return this.path
                    .replace(`:${PublicPathsParams.AdminId}`, adminId)
                    .replace(`:${AdminPathsParams.GroupId}`, employeeId)
                }
              }
            }
          }
        },
        get map() {
          return {
            path: `${AdminRouting.root.path}/map`,
            getRoute(adminId: string) {
              return `${AdminRouting.root.getRoute(adminId)}/map`
            }
          }
        }
      }
    },

    get enterprises() {
      return {
        path: `${this.path}/${AdminDashboardPages.Enterprises}`,
        getRoute: this.getRoute,
        get detail() {
          return {
            path: `${this.path}/:${AdminPathsParams.EnterpriseId}/:${AdminSubPagePathsParams.EnterprisePage}(members)?`,
            getRoute(adminId: string, enterpriseId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminPathsParams.EnterpriseId}`, enterpriseId)
                .replace(`/:${AdminSubPagePathsParams.EnterprisePage}(members)?`, '')
            }
          }
        },
        get members() {
          return {
            path: `${this.path}/:${AdminPathsParams.EnterpriseId}/members`,
            getRoute(adminId: string, enterpriseId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminPathsParams.EnterpriseId}`, enterpriseId)
            }
          }
        },
        get managers() {
          return {
            path: `${this.path}/:${AdminPathsParams.EnterpriseId}/managers`,
            getRoute(adminId: string, enterpriseId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminPathsParams.EnterpriseId}`, enterpriseId)
            }
          }
        },
        get creation() {
          return {
            path: `${this.path}/create/:${AdminPathsParams.CreationStep}(${Object.values(
              AdminEnterpriseCreationPages
            ).join('|')})?`,
            getRoute(adminId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(
                  `/:${AdminPathsParams.CreationStep}(${Object.values(AdminEnterpriseCreationPages).join('|')})?`,
                  ''
                )
            }
          }
        }
      }
    },

    get wallets() {
      const availablePages = `${AdminWalletPages.Wallets}|${AdminWalletPages.Enrollments}`
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.WalletPage}(${availablePages})/:${AdminPathsParams.EnrolmentOrWalletId}?`,
        getRoute(adminId: string, page: AdminWalletPages) {
          return `${AdminRouting.root.getRoute(adminId)}/${page}`
        },
        get detail() {
          return {
            path: this.path.replace('?', ''),
            getRoute(adminId: string, page: AdminWalletPages, id: string) {
              return `${AdminRouting.root.wallets.getRoute(adminId, page)}/${id}`
            }
          }
        },
        get creation() {
          return {
            path: `${AdminRouting.root.path}/${AdminWalletPages.Wallets}/${ActionPages.Create}/:${
              AdminPathsParams.CreationStep
            }(${Object.values(AdminWalletCreationPages).join('|')})?`,
            getRoute(adminId: string) {
              return `${AdminRouting.root.getRoute(adminId)}/${AdminWalletPages.Wallets}/${ActionPages.Create}`
            }
          }
        }
      }
    },

    get announcements() {
      const availablePages = AdminAnnouncementPages.Announcements
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.AnnouncementPage}(${availablePages})/:${AdminPathsParams.AnnouncementId}(${uuidPattern}|${ActionPages.Create})?`,
        getRoute(adminId: string, page: AdminAnnouncementPages) {
          return `${AdminRouting.root.getRoute(adminId)}/${page}`
        },
        get detail() {
          return {
            path: this.path.replace('?', ''),
            getRoute(adminId: string, page: AdminAnnouncementPages.Announcements, id: string) {
              return `${AdminRouting.root.announcements.getRoute(adminId, page)}/${id}`
            }
          }
        },
        get creation() {
          return {
            path: `${AdminRouting.root.path}/${AdminAnnouncementPages.Announcements}/${ActionPages.Create}`,
            getRoute(adminId: string) {
              return `${AdminRouting.root.getRoute(adminId)}/${AdminAnnouncementPages.Announcements}/${
                ActionPages.Create
              }`
            }
          }
        }
      }
    },

    get submissions() {
      return {
        path: `${this.path}/${AdminDashboardPages.Submissions}`,
        getRoute(adminId: string) {
          return this.path.replace(`:${PublicPathsParams.AdminId}`, adminId)
        },
        get detail() {
          return {
            path: `${this.path}/:${AdminPathsParams.SubmissionId}(${uuidPattern})`,
            getRoute(adminId: string, submissionId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminPathsParams.SubmissionId}`, submissionId)
                .replace(`(${uuidPattern})`, '')
            }
          }
        }
      }
    },

    get plans() {
      const availablePages = `${AdminDashboardPages.Subscriptions}|${AdminDashboardPages.Plans}`
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.PlanPage}(${availablePages})/:${AdminPathsParams.SubscriptionOrPlanId}(${uuidPattern})?`,
        getRoute(adminId: string, page: AdminPlansPages) {
          return `${AdminRouting.root.getRoute(adminId)}/${page}`
        },
        get detail() {
          return {
            path: this.path.replace('?', ''),
            getRoute(adminId: string, page: AdminPlansPages, id: string) {
              return `${AdminRouting.root.plans.getRoute(adminId, page)}/${id}`
            },
            get createWallet() {
              return {
                path: `${this.path}/wallets/create`,
                getRoute(adminId: string, page: AdminPlansPages, id: string): string {
                  return `${AdminRouting.root.plans.getRoute(adminId, page)}/${id}/wallets/create`
                }
              }
            },
            get selectWallets() {
              return {
                path: `${this.path}/wallets/select`,
                getRoute(adminId: string, page: AdminPlansPages, id: string): string {
                  return `${AdminRouting.root.plans.getRoute(adminId, page)}/${id}/wallets/select`
                }
              }
            },
            get groups() {
              return {
                path: `${this.path}/groups`,
                getRoute(adminId: string, page: AdminPlansPages, id: string): string {
                  return `${AdminRouting.root.plans.getRoute(adminId, page)}/${id}/groups`
                }
              }
            }
          }
        }
      }
    },
    get settings() {
      const availablePages = `${AdminDashboardPages.Integrations}|${AdminDashboardPages.Managers}`
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.SettingsPage}(${availablePages})`,
        getRoute(adminId: string, page: AdminSettingsPages) {
          return `${AdminRouting.root.getRoute(adminId)}/${page}`
        }
      }
    }
  },

  get basePage() {
    // Add all sub menu pages here with concatenation when the time has come to have many
    // menu items with sub-pages
    const availablePages = Object.values(AnalyticsSubPages).join('|')
    return {
      path: `${this.root.path}/:${AdminPathsParams.MenuPage}/:${AdminPathsParams.SubMenuPage}(${availablePages})?`,
      getRoute(adminId: string, page: string, subPage?: string): string {
        return `/admins/${adminId}/${page}${subPage ? `/${subPage}` : ''}`
      }
    }
  }
}

export const LegacyRouting = {
  users: {
    path: `/users/:userId(${uuidPattern})`,
    getRoute(): string {
      return this.path
    }
  },
  subscriptions: {
    path: '/subscriptions',
    getRoute(): string {
      return this.path
    },
    get success() {
      return {
        path: `${this.path}/success`,
        getRoute(): string {
          return this.path
        }
      }
    }
  }
}

import { Method } from 'axios'
import { GetResponse, RequestParams } from 'shared/interfaces/Api'
import { apiCall, dashboardApiServer } from '../../index'
import endpoints from './endpoints'
import logger from 'utils/logRocket'

const apiDashboardServer = apiCall(dashboardApiServer)

interface Vehicle {
  externalId: number
  make: string
  model: string
  classification: string
  year: number
  uuid: string
  engineType: string
  combinedMpg: number
  maxMpg: number
  minMpg: number
  price: number
  createdAt: string
  updatedAt: string
}

export const accountVehicleDefaultFields = ['uuid', 'year', 'make', 'model', 'engineType', 'createdAt'] as const
export const accountVehicleDefaultRelations = ['vehicle'] as const
export type AccountVehicle = Pick<Vehicle, (typeof accountVehicleDefaultFields)[number]>
export interface FetchAccountVehicleResponse {
  id: string
  vehicleId: string
  accountId: string
  color?: string
  licensePlateState?: string
  licensePlate?: string
  vehicle: AccountVehicle
}

// --- GET ---
export const fetchYears = (queryParams = {}) =>
  apiDashboardServer(endpoints.GET.VehiclesYears.route(), {
    method: 'get',
    queryParams: {
      sort: 'DESC',
      ...queryParams
    }
  })

export const fetchMakes = (queryParams = {}) => apiDashboardServer(endpoints.GET.VehiclesMakes.route(), {
    method: 'get' as Method,
    queryParams: { sort: 'ASC', ...queryParams }
  })

export const fetchModels = async (queryParams = {}) => {
  try {
    const response = await apiDashboardServer(endpoints.GET.VehiclesModels.route(), {
      method: 'get' as Method,
      queryParams: { sort: 'ASC', ...queryParams }
    })
    const labels = new Set()
    const uniqueData = response.filter((item) => {
      if (!labels.has(item.name)) {
        labels.add(item.name)
        return true
      }
      return false
    })

    return uniqueData
  } catch (error) {
    logger.error('Error fetching vehicle models:', error)
    throw error
  }
}

export const fetchEngineTypes = async (queryParams = {}) => {
  try {
    return await apiDashboardServer(endpoints.GET.VehiclesEngineTypes.route(), {
      method: 'get' as Method,
      queryParams: { sort: 'ASC', ...queryParams }
    })
  } catch (error) {
    logger.error('Error fetching engine types:', error)
    throw error
  }
}

export const fetchAccountVehicles = (
  queryParams: RequestParams & { id: string[] }
): Promise<GetResponse<FetchAccountVehicleResponse>> =>
  apiDashboardServer(endpoints.GET.AccountVehicles.route(), {
    method: 'get',
    queryParams: {
      relations: accountVehicleDefaultRelations,
      fields: [
        'color',
        'licensePlate',
        'licensePlateState',
        'vehicleId',
        'accountId',
        ...accountVehicleDefaultFields.map((field) => `vehicle.${field}`)
      ],
      ...queryParams
    }
  })

// --- POST ---
export type AccountVehicleWithoutRelations = Omit<FetchAccountVehicleResponse, 'vehicle'>
export type PostAccountVehicleBody = Omit<AccountVehicleWithoutRelations, 'id'>

export const postAccountVehicles = (data: PostAccountVehicleBody): Promise<AccountVehicleWithoutRelations> =>
  apiDashboardServer(endpoints.POST.AccountVehicles.route(), {
    method: 'post',
    data
  })

// --- UPDATES (PATCH or PUT) ---
export const putAccountVehicles = (data: AccountVehicleWithoutRelations): Promise<AccountVehicleWithoutRelations> =>
  apiDashboardServer(endpoints.PUT.AccountVehicles.route(), {
    method: 'put',
    data
  })

import { ModeName } from '@commutifi/modes'
import { MobilityService } from 'api/modules/mobilityServices'

export const mobilityServicesResultMock: { records: MobilityService[] } = {
  records: [
    {
      id: 'strava',
      displayName: 'Strava',
      description:
        'Strava is an internet service for tracking human exercise which incorporates social network features. It is mostly used for cycling and running using GPS data.',
      isConnected: true,
      hasDeauthorization: true,
      supportedModes: ['walking', 'bicycling'],
      logoUrl: 'https://via.placeholder.com/150',
      websiteUrl: 'https://strava.com/',
      oauthType: 'authorization'
    },
    {
      id: 'maps',
      displayName: 'Google Maps',
      description: 'Google Maps is a web mapping service developed by Google.',
      isConnected: false,
      hasDeauthorization: true,
      supportedModes: ['walking', 'bicycling', 'driving', 'public_transit'],
      logoUrl: 'https://via.placeholder.com/300',
      websiteUrl: 'https://www.google.com/maps/',
      oauthType: 'authorization'
    },
    {
      id: 'hytch',
      displayName: 'Hytch Rewards',
      description: 'Helping employers motivate and measure a safer, greener, smarter commute.',
      isConnected: true,
      hasDeauthorization: false,
      logoUrl: 'https://via.placeholder.com/200',
      websiteUrl: 'https://hytch.me/',
      oauthType: 'implicit',
      supportedModes: [ModeName.Bike]
    }
  ]
}
export const redirectUrlMock = 'www.google.com'

import faker from 'faker/locale/en'
import { times } from 'lodash'
import {
  EnterpriseNotificationTemplateType,
  EnterpriseNotificationTemplatesProps,
  NotificationChannel
} from '@commutifi/models/EnterpriseNotificationTemplates'
import { AttributeAnswerType, AttributeProps } from '@commutifi/models/Attributes'
import { Countries } from '@commutifi/constants/Countries'
import { EnterpriseKind } from '@commutifi/models/Enterprises'
import { IOrganization } from 'shared/interfaces'
import { EnterpriseBalanceDetails } from 'api/modules/enterprises'
import { fakeCommutifiEmail, randomIn } from '../../_utils'
import { fakeOffice } from '../offices/fakers'
import { FeatureFlagProps } from '@commutifi/models/FeatureFlag'

export const fakeAttribute = (base: Partial<AttributeProps> = {}): AttributeProps => ({
  id: base.id || faker.datatype.uuid(),
  enterpriseId: base.enterpriseId || faker.datatype.uuid(),
  key: base.key || faker.lorem.word(),
  answerOptions: base.answerOptions || [faker.random.word(), faker.random.word()],
  answerType: base.answerType || faker.random.arrayElement(Object.values(AttributeAnswerType)),
  referenceName: base.referenceName || faker.random.words(2),
  createdAt: base.createdAt || faker.date.past().toISOString(),
  updatedAt: base.updatedAt || faker.date.past().toISOString(),
  visible: base.visible ?? true
})

export const fakeEnterprise = (
  baseOrg: Partial<IOrganization & { organization: Partial<IOrganization> }> = {}
): IOrganization & { organization: IOrganization } => ({
  id: baseOrg.id || faker.datatype.uuid(),
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.past().toISOString(),
  name: baseOrg.name || faker.company.companyName(),
  organizationId: baseOrg.organizationId || faker.datatype.uuid(),
  billingEmail: baseOrg.billingEmail || fakeCommutifiEmail(),
  kind: baseOrg.kind || EnterpriseKind.enterprise,
  identityProviderId: baseOrg.identityProviderId || faker.datatype.uuid(),
  canRegisterManagers: baseOrg.canRegisterManagers ?? faker.datatype.boolean(),
  organization: {
    id: faker.datatype.uuid(),
    createdAt: faker.date.past().toISOString(),
    updatedAt: faker.date.past().toISOString(),
    name: faker.company.companyName(),
    organizationId: faker.datatype.uuid(),
    billingEmail: fakeCommutifiEmail(),
    kind: EnterpriseKind.organization,
    leedAccessTokensId: faker.datatype.uuid(),
    identityProviderId: faker.datatype.uuid(),
    canRegisterManagers: false,
    linkUuid: faker.datatype.uuid(),
    ...baseOrg.organization
  },
  linkUuid: baseOrg.linkUuid || faker.datatype.uuid(),
  offices: baseOrg.offices || times(randomIn({ min: 1, max: 4 }), () => fakeOffice()),
  buildings: baseOrg.buildings || times(randomIn({ min: 1, max: 4 }), () => fakeOffice().building),
  employeesCount: randomIn({ min: 1, max: 1000 }),
  legalName: baseOrg.legalName || faker.company.companyName(),
  billingAddressLine1: baseOrg.billingAddressLine1 || `${faker.address.streetAddress()} ${faker.address.streetName()}`,
  billingAddressLine2: baseOrg.billingAddressLine2 ?? null,
  billingCity: baseOrg.billingCity || faker.address.city(),
  billingCountry: (baseOrg.billingCountry || faker.address.countryCode()) as Countries,
  billingPostalCode: baseOrg.billingPostalCode || faker.address.zipCode(),
  billingState: baseOrg.billingState || faker.address.state(),
  paymentType: baseOrg.paymentType || null
})

export const fakeBalances = (base: Partial<EnterpriseBalanceDetails> = {}): EnterpriseBalanceDetails => {
  const currentBalance = randomIn({ min: 0, max: 100000 })
  const deposits = currentBalance + randomIn({ min: 0, max: 50000 })
  const pendingDeposits = 100000
  return {
    currentBalance: base.currentBalance ?? currentBalance,
    deposits: base.deposits ?? deposits,
    pendingDeposits: base.pendingDeposits ?? pendingDeposits,
    spending: base.spending ?? deposits + pendingDeposits - currentBalance
  }
}

export const fakeNotificationTemplate = (
  baseOrg: Partial<EnterpriseNotificationTemplatesProps> = {}
): EnterpriseNotificationTemplatesProps => ({
  id: baseOrg.id || faker.datatype.uuid(),
  channel: NotificationChannel.EMAIL,
  language: 'en',
  templateId: faker.datatype.uuid(),
  type: EnterpriseNotificationTemplateType.MANAGER_INVITE,
  variables: {},
  enterpriseId: faker.datatype.uuid(),
  isGlobal: false,
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.past().toISOString()
})

export const generateFakeFeatureFlags = (enterpriseId: string | null, overwrite: FeatureFlagProps = {}) => ({
  id: faker.datatype.uuid(),
  // Warning! These are also used for test cases so you need to leave them to
  // true once you are done experimenting with different API response using this payload
  enterpriseId: enterpriseId || faker.datatype.uuid(),
  bookings: 'bookings' in overwrite ? Boolean(overwrite.bookings) : true,
  complianceDdot: 'complianceDdot' in overwrite ? Boolean(overwrite.complianceDdot) : true,
  employees: 'employees' in overwrite ? Boolean(overwrite.employees) : true,
  commuterGuide: 'commuterGuide' in overwrite ? Boolean(overwrite.commuterGuide) : true,
  trips: 'trips' in overwrite ? Boolean(overwrite.trips) : true,
  routes: 'routes' in overwrite ? Boolean(overwrite.routes) : true,
  analytics: 'analytics' in overwrite ? Boolean(overwrite.analytics) : true,
  wallets: 'wallets' in overwrite ? Boolean(overwrite.wallets) : true,
  announcements: 'announcements' in overwrite ? Boolean(overwrite.announcements) : true,
  commutifiCard: 'commutifiCard' in overwrite ? Boolean(overwrite.commutifiCard) : true,
  commutifiAi: 'commutifiAi' in overwrite ? Boolean(overwrite.commutifiAi) : true,
  billing: 'billing' in overwrite ? Boolean(overwrite.billing) : true,
  adminManagement: 'adminManagement' in overwrite ? Boolean(overwrite.adminManagement) : true
})

import React, { Component, Suspense, useEffect } from 'react'
import { withProfiler } from '@sentry/react'
import type { Persistor } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider, connect } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom'
import { Alert, PageLoader, ErrorBoundary, GenericError } from '@commutifi-fe/ui'
import { DEFAULT_LOCALE } from 'constants/settings'
import history from './browserHistory'
import type { RootState, Store } from './store/index'
import storage from 'utils/storage'
import { CookiesProvider } from 'react-cookie'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  ConfigProvider,
  CurrentAccountProvider,
  OnlineStatusProvider,
  useConfig,
  useCurrentAccount,
  useOnlineStatus,
  AppThemes
} from 'shared/providers'
import CookieConsent from './CookieConsent'
import { authSelectors } from 'store/modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import {
  AdminDashboardPages,
  AdminRouting,
  CommonRouting,
  CommuterRouting,
  LegacyRouting,
  PublicRouting
} from './routing'
import { FormattedMessage } from './locales/index'
import { getAuthenticatedAccountId, setLocaleHeader } from './api'
import * as qs from 'utils/queryParams'
import { PasswordValidation } from 'views/Anonymous/views/CreatePassword'
import { LocalesType, uuidPattern } from '@commutifi-fe/constants'
import { getUnreservedSubDomains } from 'utils/domains'
import { useSubdomain } from 'api/modules/subdomains'
import { queryClient, queryCache, useReactQueryOnError } from 'api/reactQuery'
import { logger } from '@commutifi-fe/logger'
import { MetricsLocalizer } from '@commutifi-fe/commutifi-specific/Presentation/MetricsLocalizer'

const UnauthenticatedOnboarding = React.lazy(() => import('views/Anonymous/views/Onboarding'))
const BusinessDashboardProviders = React.lazy(() => import('views/SignedIn/BusinessDashboard'))
const AnonymousLandingPage = React.lazy(
  () => import('views/Anonymous/views/Onboarding/SurveyLandingPage/AnonymousLandingPage')
)
const OnboardingAuth = React.lazy(() => import('views/Anonymous/views/Auth/OnboardingAuth'))
const EnterpriseRegistration = React.lazy(() => import('views/Anonymous/views/EnterpriseRegistration'))
const UserDashboard = React.lazy(() => import('views/SignedIn/CommuterDashboard'))
const AuthenticatedOnboarding = React.lazy(() => import('views/SignedIn/CommuterDashboard/views/Onboarding'))
const StripeCustomConnectReceiver = React.lazy(
  () => import('views/SignedIn/BusinessDashboard/views/Settings/components/StripeConnect/StripeCustomConnectReceiver')
)
const OauthStripeReceiver = React.lazy(
  () => import('views/SignedIn/BusinessDashboard/views/Settings/components/StripeConnect/StripeConnectOAuthReceiver')
)
const NotFound = React.lazy(() => import('views/Anonymous/views/404'))
const Auth = React.lazy(() => import('views/Anonymous/views/Auth'))
const SelectDashboard = React.lazy(() => import('views/SignedIn/SelectDashboard'))
const InvoiceView = React.lazy(() => import('views/SignedIn/Invoice'))
const OauthProviderReceiver = React.lazy(() => import('views/Anonymous/views/Auth/views/OauthProviderReceiver'))
const OAuthReceiver = React.lazy(() => import('shared/components/common/OAuthReceiver'))
const EmailVerification = React.lazy(() => import('views/Anonymous/views/EmailVerification'))
const RedirectLegacyUsers = React.lazy(() => import('views/SignedIn/CommuterDashboard/views/RedirectLegacyUsers'))

const LOADER_ID = 'loader'
const enterpriseOrOrg = 'enterprises|organizations'

const renderLoading = () => <PageLoader />

function Dashboards() {
  const { error } = useCurrentAccount()
  if (error) {
    if (error.status === 401) {
      return <Redirect to={PublicRouting.auth.path} />
    }
    return (
      <GenericError
        type="page"
        error={error}
        title={<FormattedMessage id="error.load.account" />}
        buttonText={<FormattedMessage id="global.goBackToLogin" />}
      />
    )
  }

  return (
    <Switch>
      <Route path={`/update${PublicRouting.enterpriseSurvey.path}/`} component={AuthenticatedOnboarding} />
      <Route path={CommuterRouting.basePage.superAdmin.path} component={UserDashboard} />
      <Route path={PublicRouting.oAuth.arc.path} component={BusinessDashboardProviders} />
      <Route path={PublicRouting.oAuth.stripe.customConnect.path} component={StripeCustomConnectReceiver} />
      <Route path={PublicRouting.oAuth.stripe.path} component={OauthStripeReceiver} />
      <Route path={AdminRouting.basePage.path} component={BusinessDashboardProviders} />
      {/* Support issued email with a bad URL to access commutifi card
      Changed on 04 February 2022. You can delete if you judge this was supported for long enough
      */}
      <Route exact path="/accounts/:accountId/card" render={() => <Redirect to="/card" />} />
      <Route path={CommuterRouting.basePage.path} component={UserDashboard} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

function PrivateRoutes({
  match,
  logoutParams
}: { logoutParams?: Record<string, any> } & RouteComponentProps<{ adminId?: string; accountId?: string }>) {
  const accountToken: string | null = getAuthenticatedAccountId()

  const { cookieconsent } = useConfig()

  useEffect(() => {
    if (!cookieconsent) {
      return
    }

    // If cookie consent is available and is not accepted, we accept all cookies automatically on login. If the user
    // logs in we assume he accepts all cookies. Otherwise he will select the proper option in the cookie consent modal.
    // It is done here to make sure we catch all login attempts wherever it comes from
    // TEMP: Disable accepting all cookies on login for legal reasons and to avoid potential bugs because we run the cookies lib v2 that is not maintain anymore and can be unstable..
    // if (accountToken && cookieconsent && cookieconsent?.getUserPreferences().accepted_categories.length === 0) {
    //   cookieconsent.accept('all')
    // }

    cookieconsent?.hide()
  }, [accountToken, cookieconsent])

  // Since the modal to login the user is opened by default, it means that when it is closed
  // we check if the user is not authenticated and redirect to login screen to protect our Private Routes
  if (!accountToken && match.url !== PublicRouting.auth.path) {
    return (
      <Redirect
        to={{
          pathname: `${PublicRouting.auth.path}${logoutParams ? `?${qs.stringify(logoutParams)}` : ''}`,
          state: {
            resumePathname: match.url
          }
        }}
      />
    )
  }

  return (
    <CurrentAccountProvider urlAccountId={match.params.accountId}>
      <Switch>
        <Route exact path={CommonRouting.select.path} component={SelectDashboard} />
        <Route exact path={CommonRouting.invoice.detail.path} component={InvoiceView} />
        <Route path="*" component={Dashboards} />
      </Switch>
    </CurrentAccountProvider>
  )
}
const PrivateRoutesContainer = connect(
  (state: RootState, props: RouteComponentProps) => ({
    isAuthenticated: authSelectors.isLoggedIn(state),
    logoutParams: authSelectors.getLogoutParams(state),
    ...props
  }),
  null
)(PrivateRoutes)

const setApiHeaders = (locale: LocalesType) => {
  setLocaleHeader(locale)
}

function DashboardRoutes() {
  return (
    <Switch>
      <Route path={PublicRouting.auth.path} component={Auth} />
      <Route exact path={PublicRouting.root.path} render={() => <Redirect to={PublicRouting.auth.path} />} />
      <Route exact path={PublicRouting.oAuth.sso.path} component={OauthProviderReceiver} />
      <Route exact path={PublicRouting.oAuth.mobilityService.path} component={OAuthReceiver} />
      <Route exact path={PublicRouting.emailVerification.path} component={EmailVerification} />
      <Route exact path={LegacyRouting.users.path} component={RedirectLegacyUsers} />
      <Route exact path={PublicRouting.passwordCreation.path} component={PasswordValidation} />
      {/* Handle Legacy business dashboard URLs */}
      <Route
        path={`/:entity(${enterpriseOrOrg})/:entityId(${uuidPattern})`}
        render={({ match, location }) => (
          <Redirect
            to={{
              ...location,
              pathname: `${AdminRouting.root.getRoute(match.params.entityId)}${location.pathname.replace(
                match.url,
                ''
              )}`
            }}
          />
        )}
      />
      <Route
        exact
        path={AdminRouting.root.path}
        render={({ match }) => <Redirect to={`${match.url}/${AdminDashboardPages.Compliance}`} />}
      />
      {/* Watch out, this path should handle User and Admin dashboards */}
      <Route path={`${AdminRouting.root.path}/*`} component={PrivateRoutesContainer} />
      <Route path="/accounts/:accountId/*" component={PrivateRoutesContainer} />
      <Route path="*" component={PrivateRoutesContainer} />
    </Switch>
  )
}

function AppRoutes() {
  const isOnline = useOnlineStatus()
  return (
    <Suspense fallback={renderLoading()}>
      {!isOnline ? (
        <Alert
          closable
          style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000, height: '70px' }}
          banner
          message={<FormattedMessage id="global.offline.warning" />}
          className="u-elevation-3"
        />
      ) : null}
      <Switch>
        <Route
          exact
          strict
          path={PublicRouting.enterpriseRegistration.path}
          render={({ match }) => <Redirect to={`${match.url}/`} />}
        />
        <Route path={`${PublicRouting.enterpriseRegistration.path}/`} component={EnterpriseRegistration} />
        <Route path={PublicRouting.enterpriseSurvey.auth.path} component={OnboardingAuth} />
        <Route path={PublicRouting.enterpriseSurvey.publicWelcome.path} component={AnonymousLandingPage} />
        <Route
          exact
          strict
          path={PublicRouting.enterpriseSurvey.path}
          render={({ match }) => <Redirect to={`${match.url}/`} />}
        />
        <Route path={`${PublicRouting.enterpriseSurvey.path}/`} component={UnauthenticatedOnboarding} />
        <Route path="*" component={DashboardRoutes} />
      </Switch>
    </Suspense>
  )
}

function App({ locale, isAuthenticated }: { locale: LocalesType | undefined; isAuthenticated?: boolean }) {
  /**
   * Setup the error handling for the React Query so we can pass some error state in the 'meta' property of
   * useQuery and it will by handled by this function to show a toast with the error message
   */
  const onError = useReactQueryOnError()
  useEffect(() => {
    queryClient.getQueryCache().config.onError = onError
  }, [onError])

  const {
    theme: { currentTheme }
  } = useConfig()
  React.useEffect(() => {
    if (currentTheme === AppThemes.DARK) {
      document.body.classList.add('app-theme--dark')
    } else if (currentTheme === AppThemes.LIGHT) {
      document.body.classList.remove('app-theme--dark')
    }
  }, [currentTheme])

  React.useEffect(() => setApiHeaders(locale || DEFAULT_LOCALE), [locale])
  React.useEffect(() => {
    // Source: https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press
    // Detect if the user is using his keyboard to navigate so we
    // can show the outlines to respect the web Accessibility standards
    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse')
    })

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', (event) => {
      if (event.keyCode === 9) {
        document.body.classList.remove('using-mouse')
      }
    })
  }, [])

  // If we display the dashboard with org or ent. subdomain
  // eg.: https://wework.commutifi.com
  const urlSubdomain = React.useMemo(() => getUnreservedSubDomains(window.location.href)[0], [])
  const { data: subdomainData, isLoading: isLoadingSubdomain, error: subdomainError } = useSubdomain()

  React.useEffect(() => {
    if (!urlSubdomain && subdomainData) {
      const subdomainQuery = queryCache.find({ queryKey: subdomainData.queryKey })
      if (subdomainQuery) {
        queryCache.remove(subdomainQuery)
      }
    }
  }, [isAuthenticated, urlSubdomain, subdomainData])

  return subdomainError ? (
    <GenericError
      type="page"
      error={subdomainError}
      title={<FormattedMessage id="error.load.account" />}
      buttonText={<FormattedMessage id="global.goBackToLogin" />}
    />
  ) : isLoadingSubdomain ? (
    <PageLoader />
  ) : (
    <div className="viewport">
      <AppRoutes />
    </div>
  )
}

function TopLevelError({ error }: { error: any }) {
  // Handle failed lazy loading of a JS/CSS chunk. We will try to reload the page
  React.useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      // Avoid infinite loop by setting a expiry
      if (!storage.getWithExpiry('chunk_failed')) {
        storage.putWithExpiry('chunk_failed', 'true', 10000)
        window.location.reload()
      }
    }
  }, [error])

  return (
    <GenericError
      title="Unexpected Error"
      description={`Sorry for the inconvenience, please refresh and if it persists reach out to us and let us help you. ${error?.message}`}
      buttonText="Go Back Home"
    />
  )
}

class AppWithErrorBoundaries extends Component<AppWithErrorBoundariesProps & AppWithErrorBoundariesReduxProps> {
  componentDidMount() {
    this.removeLoadingNode()
  }

  handleError = (e: unknown) => {
    logger.error(JSON.stringify(e))
  }

  removeLoadingNode() {
    try {
      // App is mounted we don't need the page loader anymore.
      // It was set in the html to have a 'splash screen'
      const loadingNode = document.getElementById(LOADER_ID)
      if (loadingNode) {
        loadingNode.remove()
      }
    } catch {
      // Do nothing
    }
  }

  render() {
    const { store, persistor, locale, isAuthenticated } = this.props
    return (
      <ErrorBoundary onError={this.handleError} ErrorComponent={TopLevelError}>
        <CookiesProvider>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <PersistGate loading={null} persistor={persistor}>
                <ConfigProvider locale={locale}>
                  <OnlineStatusProvider>
                    <Router history={history}>
                      <MetricsLocalizer locale={locale}>
                        <App isAuthenticated={isAuthenticated} locale={locale} />
                      </MetricsLocalizer>
                    </Router>
                  </OnlineStatusProvider>
                  <CookieConsent />
                </ConfigProvider>
              </PersistGate>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </Provider>
        </CookiesProvider>
      </ErrorBoundary>
    )
  }
}

interface AppWithErrorBoundariesProps {
  store: Store
  persistor: Persistor
}
interface AppWithErrorBoundariesReduxProps {
  locale?: LocalesType
  isAuthenticated?: boolean
}

const mapStateToProps = (state: RootState) => {
  const { locale } = state.localSettings

  return {
    locale,
    isAuthenticated: authSelectors.isLoggedIn(state) as boolean | undefined,
    logoutParams: authSelectors.getLogoutParams(state) as Record<string, any> | undefined
  }
}

const ConnectedApp = connect<
  ReturnType<typeof mapStateToProps>,
  unknown,
  AppWithErrorBoundariesProps,
  RootState & PersistPartial
>(
  mapStateToProps,
  null
)(withProfiler(AppWithErrorBoundaries))

export default ConnectedApp

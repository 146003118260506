import {
  SupportedLocales,
  FrontendLocales,
  extractLocaleInformation,
} from "@commutifi/constants/Locale";
import {
  AU,
  BE,
  CA,
  CZ,
  DE,
  ES,
  GB,
  IT,
  NL,
  PH,
  PL,
  PT,
  TH,
  US,
  VI,
} from "country-flag-icons/react/1x1";
import type { FlagComponent } from "country-flag-icons/react/1x1";

export { extractLocaleInformation };
export type LocalesType = SupportedLocales | FrontendLocales;
export const isSupportedLocaleType = (
  locale: string | undefined
): locale is LocalesType =>
  Boolean(
    locale &&
      SupportedLocales[locale.replace("-", "") as keyof typeof SupportedLocales]
  );

export const Locales = {
  ...SupportedLocales,
  ...FrontendLocales,
};

export const AVAILABLE_LANGUAGES: {
  id: LocalesType;
  label: string;
  icon: FlagComponent;
}[] = [
  {
    id: SupportedLocales.cs,
    label: "čeština",
    icon: CZ,
  },
  {
    id: SupportedLocales.deDE,
    label: "Deutsch (Deutschland)",
    icon: DE,
  },
  {
    id: SupportedLocales.enUS,
    label: "English (US)",
    icon: US,
  },
  {
    id: SupportedLocales.enCA,
    label: "English (Canada)",
    icon: CA,
  },
  {
    id: SupportedLocales.enAU,
    label: "English (Australia)",
    icon: AU,
  },
  {
    id: SupportedLocales.enGB,
    label: "English (UK)",
    icon: GB,
  },
  {
    id: FrontendLocales.enVI,
    label: "English (Việt Nam)",
    icon: VI,
  },
  {
    id: FrontendLocales.enTH,
    label: "English (ประเทศไทย)",
    icon: TH,
  },
  {
    id: FrontendLocales.enPH,
    label: "English (Pilipinas)",
    icon: PH,
  },
  {
    id: SupportedLocales.esES,
    label: "Español (España)",
    icon: ES,
  },
  {
    id: SupportedLocales.frCA,
    label: "Français (Canada)",
    icon: CA,
  },
  {
    id: SupportedLocales.itIT,
    label: "Italiano (Italia)",
    icon: IT,
  },
  {
    id: SupportedLocales.nlNL,
    label: "Nederlands",
    icon: NL,
  },
  {
    id: SupportedLocales.nlBE,
    label: "Nederlands (België)",
    icon: BE,
  },
  {
    id: SupportedLocales.pl,
    label: "Polski",
    icon: PL,
  },
  {
    id: SupportedLocales.ptPT,
    label: "Português (Portugal)",
    icon: PT,
  },
];

export const getLocaleDisplayConfig = (
  locale: LocalesType | `${LocalesType}`
) =>
  AVAILABLE_LANGUAGES.find((l) => l.id === locale) ||
  AVAILABLE_LANGUAGES.find((l) => l.id === Locales.enUS);

import faker from 'faker/locale/en'
import { get } from 'lodash'
import times from 'lodash/times'
import moment from 'moment-timezone'
import type { Overwrite } from 'utility-types'
import { ResponseResolverInfo } from 'msw/lib/core/handlers/RequestHandler'
import { HttpRequestResolverExtras } from 'msw/lib/core/handlers/HttpHandler'
import type { Countries } from '@commutifi/constants/Countries'
import { GetResponse } from '@commutifi-fe/interfaces'
import type { Address } from 'shared/interfaces'
import { formatDateBoundaries } from 'utils/moment'
import { StripePostalAddress } from '@commutifi/models/shared/StripePostalAddress'

interface FakerNumberOptions {
  min?: number
  max?: number
  precision?: number
}

export const fakeTimezone = () => 'America/Denver'

export const randomCount = (max?: number) => faker.datatype.number({ min: 1, max: max || 12 })
export const randomIn = ({ min, max }: FakerNumberOptions) =>
  faker.datatype.number({
    min,
    max
  })

export const generateMD = () =>
  faker.datatype.boolean()
    ? faker.lorem.paragraphs(randomIn({ min: 1, max: 3 }))
    : `
---
__Advertisement :)__
- __[pica](https://nodeca.github.io/pica/demo/)__ - high quality and fast image resize in browser.
- __[babelfish](https://github.com/nodeca/babelfish/)__ - developer friendly i18n with plurals support and easy syntax.

You will like those projects!
---

# h1 Heading 8-)
## h2 Heading
### h3 Heading
#### h4 Heading
##### h5 Heading
###### h6 Heading

## Typographic replacements

Enable typographer option to see result.

(c) (C) (r) (R) (tm) (TM) (p) (P) +-

test.. test... test..... test?..... test!....

!!!!!! ???? ,,  -- ---

"Smartypants, double quotes" and 'single quotes'
`

export const fakeFullAddress = () =>
  `${faker.address.streetAddress()}${faker.address.streetName()}, ${faker.address.city()}, ${faker.address.countryCode()} ${faker.address.zipCode()}`

export const fakeCommutifiEmail = () =>
  faker.internet.email(faker.name.firstName(), faker.name.lastName(), 'commutifi.com')
export const fakeFullName = () => `${faker.name.firstName()} ${faker.name.lastName()}`

export const generateEndDateFromStartDate = (startDate: string, timezone: string) => {
  const endDate = formatDateBoundaries(startDate, {
    endOf: 'day',
    timezone
  }).toISOString()
  return endDate
}

export const generateNextDaysDatesFromNow = (timezone: string, nbDays?: number) => {
  const momentFromNow = moment.tz(moment.now(), timezone).startOf('day')
  const defaultNbDays = nbDays || Math.abs(momentFromNow.diff(moment(momentFromNow).add(1, 'month'), 'days'))
  return times(defaultNbDays, () => {
    const startDate = formatDateBoundaries(momentFromNow.format(), { startOf: 'day', timezone }).toISOString()
    const endDate = generateEndDateFromStartDate(startDate, timezone)
    momentFromNow.add(1, 'day')
    return { startDate, endDate }
  })
}

/**
 * This function extracts limit and offsets from the search/query params
 * to extract relevant slice of the data and build the proper API response
 * for a common get many call.
 * @param req - Mocked request object
 * @param data - Array of records (all of them not paginated)
 */
export function getPaginatedData<T extends any[]>(
  req: ResponseResolverInfo<HttpRequestResolverExtras<Record<string, any>>>['request'],
  data: T,
  options?: { defaultLimit?: number }
): GetResponse<T[number]> {
  const url = new URL(req.url)
  const rawLimit = url.searchParams.get('limit')
  const limit: number = rawLimit ? parseInt(rawLimit) : (options?.defaultLimit ?? 15)
  const rawOffset = url.searchParams.get('offset')
  const offset: number = rawOffset ? parseInt(rawOffset) : 0
  return {
    records: data.slice(offset, offset + limit),
    _metadata: { totalCount: data.length, offset: Math.min(offset + limit, data.length), limit }
  }
}

/**
 * This function extracts specified params (usually filters) and automatically
 * detect search terms to apply all of those on the data and returned the filtered
 * results
 * @param req - Mocked request object
 * @param data - Array of records (all of them not paginated)
 * @param params - Array of parameters to extract from the search query and apply on data
 */
export function applyFilters<T extends any[]>(
  req: ResponseResolverInfo<HttpRequestResolverExtras<Record<string, any>>>['request'],
  data: T,
  params?: string[]
) {
  // Get search queries and build paths there are accessing onto the data and what
  // is the search term associated
  const searchParams: { path: string[] | string; searchTerm: string }[] = []
  const url = new URL(req.url)
  const entries = [...url.searchParams.entries()]
  entries.forEach((paramEntry) => {
    // paramEntry[0] === 'searchQuery' -> Support bookings complex search
    if (paramEntry[0] === 'searchQuery') {
      const searchTerm = paramEntry[1].replace(/%/g, '')
      searchParams.push({
        path: ['account', 'name'],
        searchTerm
      })
      searchParams.push({
        path: ['account', 'email'],
        searchTerm
      })
      searchParams.push({
        path: ['bookedItem'],
        searchTerm
      })
    }

    if (paramEntry[1] && paramEntry[1].startsWith('%')) {
      searchParams.push({
        path: paramEntry[0].replace(/%:/g, '').split('.'),
        searchTerm: paramEntry[1].replace(/%/g, '')
      })
    }
  })

  // Apply search
  const searchedData = data.filter((record) => {
    if (searchParams.length === 0) {
      return true
    }
    return searchParams.some((searchParam) => {
      const attribute = get(record, searchParam.path)
      return (
        attribute &&
        typeof attribute === 'string' &&
        attribute.toLowerCase().includes(searchParam.searchTerm.toLowerCase())
      )
    })
  })

  // Parse and build filters array
  const rawFilters = (params || []).map((param) => url.searchParams.get(param))
  const filtersArrays = rawFilters
    .map((rawFilter, i) =>
      rawFilter?.split(',').every((filter) => filter !== '')
        ? { filters: rawFilter.split(','), param: (params || [])[i] }
        : undefined
    )
    .filter((f) => f) as {
    filters: string[]
    param: string
  }[]

  // Apply filters on data we previously applied the search on
  return searchedData.filter(
    (d) =>
      filtersArrays.length === 0 ||
      filtersArrays.every(({ filters, param }) => {
        const parsedParam: string[] = param && param.includes('.') ? param.split('.') : [param]
        if (parsedParam.filter((p) => p !== '').length > 0) {
          const firstLevelPick = parsedParam[0]
          const firstLevelProp = get(d, firstLevelPick)
          if (Array.isArray(firstLevelProp)) {
            parsedParam.shift()
            const filteredArray = firstLevelProp.filter(
              (objFromArray) =>
                get(objFromArray, parsedParam) &&
                (filters.length === 0 || filters.includes(get(objFromArray, parsedParam)))
            )
            d[firstLevelPick] = filteredArray
            return filteredArray.length
          }
          return get(d, parsedParam) && (filters.length === 0 || filters.includes(get(d, parsedParam)))
        }
        return false
      })
  )
}

export const fakeAddress = (
  base: Partial<Address> = {}
): Overwrite<Required<Address> & { address: string }, { country: Countries }> => {
  const streetNumber = base.streetNumber || faker.address.streetAddress()
  const streetName = base.street || faker.address.streetName()
  const city = base.city || faker.address.city()
  const postalCode = base.postalCode || faker.address.zipCode()
  const state = base.state || faker.address.state()
  const country = (base.country || faker.address.countryCode()) as Countries
  return {
    streetNumber,
    street: streetName,
    city,
    postalCode,
    state,
    country,
    address: `${streetNumber} ${streetName}, ${city}, ${state} ${postalCode}, ${country}`
  }
}

export const fakeStripeAddress = (base: Partial<StripePostalAddress> = {}): StripePostalAddress => {
  const line1 = base.line1 || `${faker.address.streetAddress()} ${faker.address.streetName()}`
  const line2 = base.line2 || null
  const city = base.city || faker.address.city()
  const postalCode = base.postal_code || faker.address.zipCode()
  const state = base.state || faker.address.state()
  const country = (base.country || faker.address.countryCode()) as Countries
  return {
    line1,
    line2,
    city,
    postal_code: postalCode,
    state,
    country
  }
}

export const getLimitParam = (req: ResponseResolverInfo<HttpRequestResolverExtras<Record<string, any>>>['request']) => {
  const url = new URL(req.url)
  const limit = url.searchParams.get('limit')
  if (!limit) {
    return undefined
  }

  return parseInt(limit)
}

export const fakeCity = (format?: string) => faker.address.city(format)

import { useCallback, useEffect, useMemo, useState } from 'react'
import { merge } from 'lodash'
import { Theme, ThemeProvider as NivoThemeProvider, useTheme } from '@nivo/core'
import { Themes } from '@commutifi-fe/ui/components/theme/constants'
import { ChartsThemeProviderProps } from './props'
import { extractThemeFromDocumentStyle } from '../theme'
import { ChartsThemeContext, ChartsThemeContextValue, defaultConfig } from './context'
import { colors } from '../theme/colors'
import defaultTheme from '../theme/themes/default.module.scss'
import darkTheme from '../theme/themes/dark.module.scss'

const fontFamily = 'Segma, sans-serif'
export const defaultNivoTheme: Partial<Theme> = {
  text: {
    fill: colors.neutralGray600,
    fontFamily
  },
  axis: {
    legend: { text: { fill: colors.neutralGray500 } },
    ticks: {
      text: { fill: colors.neutralGray600, fontSize: '10px', fontFamily },
      line: { stroke: colors.neutralGray200 }
    },
    domain: { line: { padding: 10 } }
  },
  crosshair: {
    line: { stroke: colors.neutralGray400 }
  },
  grid: {
    line: { stroke: colors.neutralGray200 }
  },
  legends: {
    text: { fill: colors.neutralGray600, fontFamily, fontWeight: 600 },
    title: { text: { fill: colors.neutralGray600, fontFamily } },
    ticks: {
      text: { fill: colors.neutralGray600, fontFamily },
      line: { stroke: colors.neutralGray200 }
    }
  },
  annotations: {
    text: { fill: colors.neutralGray600, fontFamily },
    link: { stroke: colors.neutralBlue500 }
  },
  tooltip: {
    container: {
      color: colors.neutralGray600
    }
  }
}

export function ChartsThemeProvider({ children, theme, colorScheme }: ChartsThemeProviderProps) {
  const [dynamicColors, setDynamicColors] = useState<ReturnType<typeof extractThemeFromDocumentStyle>>(
    extractThemeFromDocumentStyle()
  )

  const changeTheme = useCallback((newTheme: `${Themes}`) => {
    switch (newTheme) {
      case Themes.DARK: {
        /**
         * Apply values from the theme file
         */
        Object.entries(darkTheme).forEach(([key, value]) => {
          document.documentElement.style.setProperty(key, value)
        })

        break
      }
      default:
        Object.entries(defaultTheme).forEach(([key, value]) => {
          document.documentElement.style.setProperty(key, value)
        })
    }
    // Modifying the document styles won't trigger a re-render so we 'force' it by updating the state used
    // to set the Theme Provider value
    setDynamicColors(extractThemeFromDocumentStyle())
  }, [])

  useEffect(() => {
    changeTheme(colorScheme || Themes.LIGHT)
  }, [changeTheme, colorScheme])

  const memoedTheme = useMemo(() => merge({}, defaultNivoTheme, theme), [theme])
  const nivoTheme = useTheme()

  const memoedConfig = useMemo<ChartsThemeContextValue>(
    () =>
      merge({}, defaultConfig, {
        tokens: dynamicColors,
        theme: merge({}, memoedTheme, nivoTheme)
      }),
    [dynamicColors, memoedTheme, nivoTheme]
  )

  return (
    <NivoThemeProvider theme={memoedTheme}>
      <ChartsThemeContext.Provider value={memoedConfig}>{children}</ChartsThemeContext.Provider>
    </NivoThemeProvider>
  )
}

export default ChartsThemeProvider

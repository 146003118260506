import { MerchantCategories } from '@commutifi/constants/Stripe'
import faker from 'faker/locale/en'
import { fakeCard, fakeCardId, visaCardNumber } from './fakers'

export { fakeCardId }

interface IBalance {
  balance: number
  maxBalance: number
  spent: number
  renewalDate: string
}

export const foodCardBookingExternalId = 'food-card-miam-miam'
export const mobilityCardBookingExternalId = 'mobility-card'

export const fakeBalance: IBalance = {
  balance: 47_16,
  maxBalance: 200_16,
  spent: 152_84,
  renewalDate: faker.date.future().toISOString()
}

export const staticFakeCard = fakeCard()
export const staticFakeCardFullNumber = visaCardNumber
export const staticFakeCardCVC = '123'

export const foodCardRule = {
  id: foodCardBookingExternalId,
  ruleId: '6d6bd78d-dbcd-46d4-bf94-8a9b957ff60b',
  cardId: fakeCardId,
  rule: {
    id: '6d6bd78d-dbcd-46d4-bf94-8a9b957ff60b',
    category: [MerchantCategories.FastFoodRestaurants],
    detailedCategories: [{ code: '0999', category: MerchantCategories.FastFoodRestaurants, label: 'Food' }],
    merchantNetworkId: ['n1'],
    merchants: [{ id: '1', networkId: 'n1', name: 'Subway, Eat fresh' }],
    ...fakeBalance
  }
}
export const mobilityCardRule = {
  id: mobilityCardBookingExternalId,
  ruleId: '509e3b9f-f476-4294-802e-06d5ff953276',
  cardId: fakeCardId,
  rule: {
    id: '509e3b9f-f476-4294-802e-06d5ff953276',
    category: [MerchantCategories.TransportationServices, MerchantCategories.CommuterTransportAndFerries],
    detailedCategories: [
      { code: '0999', category: MerchantCategories.TransportationServices, label: 'Transport Services' },
      { code: '0666', category: MerchantCategories.CommuterTransportAndFerries, label: 'Commuter Transport, Ferries' }
    ],
    spendLimit: []
  }
}

import moment from 'moment-timezone'
import faker from 'faker/locale/en'
import { times } from 'lodash'
import { MerchantCategories } from '@commutifi/constants/Stripe'
import { CardStatus, CardType } from '@commutifi/models/Cards'
import { CardProps } from 'api/modules/commutifiCards'
import { fakeAccountTimezone } from '../../config/constants'
import { fakeCommutifiEmail, fakeStripeAddress } from '../../_utils'

const futureDate = faker.date.future()
export const visaCardNumber = faker.finance.creditCardNumber('visa')
export const fakeCardId = faker.datatype.uuid()

export const fakeCard = (baseCard: Partial<CardProps> = {}): CardProps => ({
  id: baseCard.id || fakeCardId,
  status: baseCard.status || CardStatus.Active,
  ownerFirstName: baseCard.ownerFirstName || faker.name.firstName(),
  ownerLastName: baseCard.ownerLastName || faker.name.lastName(),
  ownerEmail: baseCard.ownerEmail || fakeCommutifiEmail(),
  billingAddress: baseCard.billingAddress || fakeStripeAddress(),
  // Months are from 0 to 11
  expMonth: futureDate.getMonth() + 1,
  expYear: futureDate.getFullYear(),
  last4: visaCardNumber.substr(visaCardNumber.length - 4, visaCardNumber.length),
  phoneNumber: baseCard.phoneNumber || faker.phone.phoneNumber('+1 ### ### ####'),
  stripeCardId: faker.datatype.uuid(),
  timezone: fakeAccountTimezone,
  type: CardType.Virtual
})

export const generateCommutifiCardTransaction = () => ({
  id: faker.datatype.uuid(),
  authorizationDate: faker.date.past(),
  category: [
    MerchantCategories.TransportationServices,
    MerchantCategories.FastFoodRestaurants,
    MerchantCategories.EatingPlacesRestaurants
  ][faker.datatype.number(2)],
  merchantNetworkId: 'n1',
  amount: Number(faker.finance.amount(100, 10000)),
  cardId: fakeCardId,
  card: fakeCard(),
  description: 'AMTRAK MOBIL2760690591836'
})

export const generateConsecutiveCardTransactions = (total: number) => {
  const oneYearAgo = moment().subtract(1, 'year')
  return times(total, () => ({
    ...generateCommutifiCardTransaction(),
    authorizationDate: oneYearAgo.add(1, 'day').toISOString()
  }))
}

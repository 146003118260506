import { LocalesType } from '@commutifi-fe/constants'
import { CurrencyCode, FrontendLocales, SupportedLanguages, SupportedLocales } from '@commutifi/constants/Locale'
import type { LanguageCode, SupportedLanguagesType } from '@commutifi/constants/Locale'
import type { messages } from 'locales/index'

export const createArcAccountLink = 'https://www.usgbc.org/registration/create-user'
export const learnMoreAboutArcLink = 'https://arcskoru.com/learn-more-about-arc'

export const Locales = {
  ...SupportedLocales,
  ...FrontendLocales
}
export { CurrencyCode }

export const isSupportedLanguageType = (
  language: `${LanguageCode}` | undefined
): language is Lowercase<SupportedLanguagesType> =>
  Boolean(language && SupportedLanguages[language.toUpperCase() as keyof typeof SupportedLanguages])

export enum LocalesFilename {
  Cs = 'cs',
  DeDE = 'de-DE',
  EsES = 'es-ES',
  En = 'en',
  Fr = 'fr',
  ItIT = 'it-IT',
  NlNL = 'nl-NL',
  Pl = 'pl',
  PtPT = 'pt-PT'
}

export const getMomentLocale = (locale: SupportedLocales) => {
  switch (locale) {
    case SupportedLocales.cs:
      return 'cs'
    case SupportedLocales.deDE:
      return 'de'
    case SupportedLocales.enAU:
      return 'en-au'
    case SupportedLocales.enGB:
      return 'en-gb'
    case SupportedLocales.esES:
      return 'es'
    case SupportedLocales.frBE:
      return 'fr'
    case SupportedLocales.frCA:
      return 'fr-ca'
    case SupportedLocales.itIT:
      return 'it'
    case SupportedLocales.nlBE:
      return 'nl-be'
    case SupportedLocales.nlNL:
      return 'nl'
    case SupportedLocales.pl:
      return 'pl'
    case SupportedLocales.ptPT:
      return 'pt'
    case SupportedLocales.enCA:
    case SupportedLocales.enUS:
      return 'en'
    default: {
      const exhaustiveCheck: string = locale
      throw new Error(`Unhandled locale case: ${exhaustiveCheck}`)
    }
  }
}

export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`)
  }
}
export const localeToSupportedTranslationFile = (locale: LocalesType): keyof typeof messages => {
  switch (locale) {
    case SupportedLocales.cs:
      return LocalesFilename.Cs
    case SupportedLocales.deDE:
      return LocalesFilename.DeDE
    case SupportedLocales.enAU:
    case FrontendLocales.enAU:
    case SupportedLocales.enCA:
    case SupportedLocales.enGB:
    case FrontendLocales.enPH:
    case FrontendLocales.enTH:
    case SupportedLocales.enUS:
    case FrontendLocales.enVI:
      return LocalesFilename.En
    case SupportedLocales.esES:
      return LocalesFilename.EsES
    case SupportedLocales.frBE:
    case SupportedLocales.frCA:
      return LocalesFilename.Fr
    case SupportedLocales.itIT:
      return LocalesFilename.ItIT
    case SupportedLocales.nlBE:
    case SupportedLocales.nlNL:
      return LocalesFilename.NlNL
    case SupportedLocales.pl:
      return LocalesFilename.Pl
    case SupportedLocales.ptPT:
      return LocalesFilename.PtPT
    default: {
      const exhaustiveCheck: string = locale
      throw new Error(`Unhandled locale case: ${exhaustiveCheck}`)
    }
  }
}

export const STAY_LOGGED_IN_STORAGE_ID = 'stayLoggedIn'
export const DEFAULT_LOCALE = Locales.enUS
export const getLocale = (locale: LocalesType | string) => {
  if (!locale || typeof locale !== 'string') {
    return Locales.enUS
  }
  const localeKey = Object.keys(Locales).includes(locale) ? Locales[locale as keyof typeof Locales] : undefined
  return localeKey ?? Locales.enUS
}

export enum NumberType {
  Relative = 'relative',
  Absolute = 'absolute'
}

export const CHAT_WIDGET_STORAGE_ID = 'chatWidgetVisible'

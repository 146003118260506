const config = {
  dashboardApiUrl: process.env.DASHBOARD_API_URL || 'http://localhost:3002',
  logRocket: process.env.LOG_ROCKET || '',
  mapboxApiKey: process.env.MAPBOX_API_KEY || '',
  stripePk: process.env.STRIPE_PK || '',
  sentryDSN: process.env.SENTRY_DSN || '',
  arcSubscriptionKey: process.env.ARC_SUBSCRIPTION_KEY || '',
  arcClientId: process.env.ARC_CLIENT_ID || '',
  arcApiUrl: process.env.ARC_API_URL || '',
  stripeConnectApiUrl: process.env.STRIPE_CONNECT_API_URL || '',
  stripeConnectClientId: process.env.STRIPE_CONNECT_CLIENT_ID || '',
  googleApiKey: process.env.GOOGLE_API_KEY || '',
  appSocialUrl: process.env.APP_SOCIAL_URL || 'http://localhost:3001',
  sentryAuthToken: process.env.SENTRY_AUTH_TOKEN || ''
}

export default config

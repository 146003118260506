// Import default theme CSS global tokens to be able to extract the default configuration from
// document style object
import { camelCase, omit } from 'lodash'
import { ThemeConfig } from 'antd/es/config-provider'
import { CssVariablesCamelizeString } from '@commutifi-fe/interfaces'
import '../style/theme.scss'
import defaultTheme, { Styles as DefaultTheme } from '../style/themes/default.module.scss'
import { colors } from '../style/constants'

type CommutifiToken = Record<CssVariablesCamelizeString<keyof DefaultTheme>, string>

export const extractThemeFromDocumentStyle = () => {
  const styles = getComputedStyle(document.body)
  // Here we use the default theme simply because we know it has ALL the style keys
  return Object.entries(defaultTheme).reduce(
    (acc, [key, value]) => ({ ...acc, [camelCase(key)]: `rgb(${styles.getPropertyValue(key) || value})` }),
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter -- Faking CommutifiToken object
    {} as CommutifiToken
  )
}

const dynamicColors = extractThemeFromDocumentStyle()

export const defaultConfig: ThemeConfig = {
  token: {
    // Let antd algorithm generate proper background colors, it's quite good at it
    ...omit(dynamicColors, ['colorBgContainer', 'colorBgElevated', 'colorBgLayout', 'backgroundColor']),
    colorPrimary: dynamicColors.primary400 || '',
    colorLink: dynamicColors.primary400 || '',
    colorLinkHover: dynamicColors.primary500 || '',
    colorLinkActive: dynamicColors.primary500 || '',
    colorSuccess: colors.feedbackSuccess400,
    colorWarning: colors.feedbackWarning400,
    colorError: colors.feedbackDestructive500,
    colorIcon: dynamicColors.neutral600 || '',
    colorText: dynamicColors.neutral600 || '',
    colorTextDisabled: dynamicColors.neutral500 || '',
    colorTextHeading: dynamicColors.neutral600 || '',
    colorTextPlaceholder: dynamicColors.neutral500 || '',
    colorTextSecondary: dynamicColors.neutral500 || '',
    borderRadius: 4,
    fontFamily: 'Segma',
    colorPrimaryActive: dynamicColors.primary500 || '',
    colorPrimaryHover: dynamicColors.primary500 || '',
    colorErrorHover: colors.feedbackDestructive500,
    fontSizeSM: 12,
    fontSize: 14,
    fontSizeLG: 16,
    lineHeight: 1.5714,
    lineHeightSM: 1.5,
    lineHeightLG: 1.625,
    controlHeightSM: 32,
    controlHeight: 40
  },
  components: {
    Alert: {
      colorInfoBg: dynamicColors.neutral200 || '',
      colorWarningBg: colors.feedbackWarning400,
      colorWarningBorder: 'transparent',
      colorSuccessBg: colors.feedbackSuccess200,
      colorSuccessBorder: 'transparent',
      colorErrorBg: colors.feedbackDestructive100,
      colorErrorBorder: '#B82608'
    },
    Button: {
      borderRadius: 6,
      colorPrimaryBorder: 'unset',
      colorError: colors.feedbackDestructive400,
      colorBgTextHover: 'transparent',
      colorPrimaryTextHover: 'blue'
    },
    Checkbox: {
      colorPrimary: dynamicColors.primary350 || '',
      colorPrimaryHover: dynamicColors.primary400 || '',
      controlInteractiveSize: 16
    },
    Divider: {
      colorSplit: dynamicColors.neutral300 || '',
      fontSizeLG: 14,
      colorBgTextHover: 'transparent'
    },
    InputNumber: {
      controlOutlineWidth: 1,
      colorErrorOutline: colors.feedbackDestructive500
    },
    Menu: {
      activeBarBorderWidth: 2
    },
    Message: {
      colorBgElevated: dynamicColors.colorBgLayout || '',
      boxShadow: '0px 12px 24px -10px rgba(146, 148, 152, 0.301054)',
      borderRadiusLG: 6
    },
    Notification: {
      borderRadius: 6
    },
    Radio: {
      colorPrimary: dynamicColors.primary350 || ''
    },
    Select: {
      controlOutlineWidth: 1
    },
    Switch: {
      colorPrimary: dynamicColors.primary350 || '',
      colorPrimaryHover: dynamicColors.primary400 || '',
      controlHeight: 32
    },
    Tag: {
      lineType: 'none',
      colorText: dynamicColors.neutral600 || '',
      colorSuccess: dynamicColors.neutral600 || '',
      colorSuccessBg: colors.feedbackSuccess200 || '',
      colorError: dynamicColors.neutral600,
      colorErrorBg: colors.feedbackDestructive200,
      colorWarning: dynamicColors.neutral600 || '',
      colorWarningBg: '#FFE8A8'
    },
    Tree: {
      colorPrimary: dynamicColors.primary350 || '',
      colorPrimaryHover: dynamicColors.primary400 || '',
      controlInteractiveSize: 16
    }
  }
}

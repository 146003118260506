import type { AnalyticsQueryResponse } from 'api/modules/analytics'

export enum MapType {
  Density = 'density',
  Points = 'points'
}

export enum LayerType {
  DensityMap = 'density'
}

export enum IconType {
  Office = 'office',
  Drive = 'drive',
  Carpool = 'carpool',
  Shared = 'shared',
  Bike = 'bike',
  Walk = 'walk',
  Remote = 'remote',
  Transit = 'transit',
  Default = 'default'
}

export type ViewState = {
  longitude: number
  latitude: number
  zoom: number
  minZoom: number
  maxZoom: number
  pitch: number
  bearing: number
}

export type AnnotationsType = AnalyticsQueryResponse['visualization']['annotations'] | undefined

export type TooltipRowData = {
  label: string
  value: string | number | null
}

import { IAxiosRetryConfig } from 'axios-retry'
import { AxiosError } from 'axios'
import { EnterpriseSurveyProps } from '@commutifi/models/EnterprisesSurveys'
import { apiCall, dashboardApiServer } from '../../index'
import endpoints from './endpoints'

const apiDashboardServer = apiCall(dashboardApiServer)

interface SurveyRequestBody {
  enterpriseSurveyId?: string
  answers: { questionId?: string; referenceKey?: string; value: any }[]
  // For SSO
  authorizationCode?: string
  state?: string
  identityProviderId?: string
}

interface AccountBody {
  name: string
  email: string
  // Optional because of SSO
  password?: string
}

interface EnterpriseSurveyLinkGenerationBody {
  enterpriseId?: string
  organizationId?: string
  accountId?: string
  buildingId?: string
}

export const fetchEnterpriseSurvey = (queryParams: { shortlinkId: string }) =>
  apiDashboardServer(endpoints.GET.EnterprisesSurveys.route(), {
    method: 'get',
    queryParams
  })

export const fetchDefaultSurvey = (queryParams: { accountId: string }) =>
  apiDashboardServer(endpoints.GET.DefaultSurvey.route(), {
    method: 'get',
    queryParams
  })

const postSurveyAxiosRetryConfig: IAxiosRetryConfig = {
  retries: 3,
  retryCondition: (error: AxiosError) => error.request.status === 0
}

export const postOnboardSurveyWithoutAccount = (surveyId: string, data: SurveyRequestBody) =>
  apiDashboardServer(endpoints.POST.SurveyAnswers.route(surveyId), {
    method: 'post',
    data,
    'axios-retry': postSurveyAxiosRetryConfig
  })

export const postOnboardSurveyWithAccount = (accountId: string, data: SurveyRequestBody) =>
  apiDashboardServer(endpoints.POST.OnboardSurveyWithAccount.route(accountId), {
    method: 'post',
    data,
    'axios-retry': postSurveyAxiosRetryConfig
  })

export const postFollowUpSurvey = (accountId: string, data: SurveyRequestBody) =>
  apiDashboardServer(endpoints.POST.FollowUpSurvey.route(accountId), {
    method: 'post',
    data,
    'axios-retry': postSurveyAxiosRetryConfig
  })

/**
 * Create or fetch EnterpriseSurvey related to the query params used to narrow down the results
 * of survey invite EnterpriseSurvey
 * @param data - Body parameters see {@link EnterpriseSurveyLinkGenerationBody}
 * @param queryParams - Query parameters
 * @param   generateSurveyLink - Whether or not to generate a survey link
 */
export const createOrFetchEnterpriseSurvey = (
  data: EnterpriseSurveyLinkGenerationBody,
  queryParams?: { generateSurveyLink?: boolean }
): Promise<{
  data: {
    enterpriseSurvey: EnterpriseSurveyProps & { shortlinkId: string }
    surveyLink: string
  }
}> =>
  apiDashboardServer(endpoints.POST.EnterprisesSurveys.route(), {
    method: 'post',
    data,
    queryParams
  })

/**
 *
 * @param data - Patch data
 * @param   name - Name to patch anonymous data with
 * @param   email - Email to patch anonymous data with
 * @param   password - Password to patch anonymous data with
 * @returns Promise of the account id to redirect to the dashboard
 */
export const patchAnonymousSurveyAccount = (surveyId: string, data: AccountBody) =>
  apiDashboardServer(endpoints.PATCH.AnonymousSurveyAccount.route(surveyId), {
    method: 'patch',
    data
  })
